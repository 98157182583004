import { createContext } from 'react';
import useKabayans from '../../hooks/useKabayans';

export const KabayansContext = createContext({});

export const KabayansProvider = ({ children }) => {
  const { setKabayansContext, kabayansContext } = useKabayans();
  return (
    <KabayansContext.Provider value={{ setKabayansContext, kabayansContext }}>{children}</KabayansContext.Provider>
  );
};
