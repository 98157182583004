import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const HistoryDetailFunctionPages = loadable(() => import(/* webpackChunkName: "InterviewPages" */ './index'));
const historyDetailFunctionRoute = {
  routeProps: {
    path: PATH.HISTORY_DETAIL,
    component: HistoryDetailFunctionPages,
  },
  isPublic: false,
  name: 'HistoryDetailFunction',
};

export default historyDetailFunctionRoute;
