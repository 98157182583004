import { bool } from 'prop-types';
import { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { AuthContext } from 'utils/context/AuthContext';
import { FEATURE_RBAC } from 'utils/features';
import { useCan } from 'utils/libs/accessControl';
import membersFunctionRoute from 'pages/MembersFunction/routes';
import contractFarmingRoute from 'pages/ContractFarming/routes';

const PrivateRoute = props => {
  const location = window.location.pathname;
  const { isAuthLoginV2, isSuperadmin } = useContext(AuthContext);
  const { isGuest, isUnderConstruction } = props;

  const { can: canAccessContractFarming } = useCan({
    resource: contractFarmingRoute.name,
    action: 'show',
    enabled: location === '/',
  });

  const { can: canAccessMember } = useCan({
    resource: membersFunctionRoute.name,
    action: 'show',
    enabled: location === '/',
  });

  if (isAuthLoginV2) {
    if (isGuest) return <Redirect to="/" />;
    if (isUnderConstruction) return <Redirect to="/under-construction" />;

    if (!FEATURE_RBAC && location === '/') return <Redirect from="/" to="/list-members" />;

    // check is superadmin
    if (FEATURE_RBAC && location === '/' && isSuperadmin) return <Redirect from="/" to="/list-members" />;

    // access control sales fish or shrimp
    if (FEATURE_RBAC && location === '/' && !isSuperadmin) {
      if (canAccessContractFarming) return <Redirect from="/" to="/contract-farming" />;
      if (canAccessMember) return <Redirect from="/" to="/list-members" />;
    }

    return <Route {...props} />;
  }

  if (!isAuthLoginV2 && isGuest) {
    if (isUnderConstruction) return <Redirect to="/under-construction" />;

    return <Route {...props} />;
  }

  return (window.location.href = `/login${!['/', '/under-construction'].includes(location) ? `?ref=${location}` : ''}`);
};

PrivateRoute.propTypes = {
  isGuest: bool,
};

PrivateRoute.defaultProps = {
  isGuest: false,
};

export default PrivateRoute;
