import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const HistoryDetailRenewalFunctionPages = loadable(() => import(/* webpackChunkName: "InterviewPages" */ './index'));
const historyDetailRenewalFunctionRoute = {
  routeProps: {
    path: PATH.HISTORY_DETAIL_RENEWAL,
    component: HistoryDetailRenewalFunctionPages,
  },
  isPublic: false,
  name: 'HistoryDetailRenewalFunction',
};

export default historyDetailRenewalFunctionRoute;
