import React from 'react';
import { render } from 'react-dom';
import TagManager from 'react-gtm-module';
import GlobalStyles from './GlobalStyles';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import 'material-icons/iconfont/material-icons.css';

// import errorReporter from 'utils/libs/errorReporter';

// errorReporter.initialize({
//   appName: 'bandeng',
//   dsn: process.env.REACT_APP_DSN_SENTRY,
//   env: process.env.REACT_APP_MODE,
// });

// GTM
const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_CODE,
};

TagManager.initialize(tagManagerArgs);

render(
  <main id="wrapper">
    <GlobalStyles />
    <App />
  </main>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();
