import React from 'react';
import Modal from 'react-modal';
import Routing from './routes';
import AppContainer from './containers/AppContainer';

const App = () => {
  return (
    <div id="modalWrapper">
      <AppContainer>
        <Routing />
      </AppContainer>
    </div>
  );
};

Modal.setAppElement('#root');

export default App;
