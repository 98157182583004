import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const ContractFarmingPage = loadable(() => import(/* webpackChunkName: "ContractFarmingPage" */ './index'));
const contractFarmingRoute = {
  routeProps: {
    exact: true,
    path: PATH.CONTRACT_FARMING,
    component: ContractFarmingPage,
  },
  isPublic: false,
  name: 'contract_farming',
};

export default contractFarmingRoute;
