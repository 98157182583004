import { FEATURE_REACTIVATION, FEATURE_RENEWAL_TRACKER, FEATURE_SUBMISSION_CF } from 'utils/features';

import ContractFarming from 'pages/ContractFarming/routes';
import ContractFarmingSubmission from 'pages/ContractFarmingSubmission/routes';
import HistoryDetailFunction from 'pages/HistoryDetailFunction/routes';
import HistoryDetailRenewalFunction from 'pages/HistoryDetailRenewalFunction/routes';
import HistoryNominatifFunction from 'pages/HistoryNominatifFunction/routes';
import HistoryNominatifRenewalFunction from 'pages/HistoryNominatifRenewalFunction/routes';
import Home from 'pages/Home/routes';
import InterviewFunction from 'pages/InterviewFunction/routes';
import KabayanPart1Function from 'pages/KabayanPart1Function/routes';
import KabayanPart2Function from 'pages/KabayanPart2Function/routes';
import Login from 'pages/Login/routes';
import MemberDetailFunction from 'pages/MemberDetailFunction/routes';
import MembersFunction from 'pages/MembersFunction/routes';
import NominationFunction from 'pages/NominationFunction/routes';
import PengajuanUlangFunction from 'pages/PengajuanUlangFunction/routes';
import PengajuanUpgradingFunction from 'pages/PengajuanUpgradingFunction/routes';
import PengajuanReactivation from 'pages/Reactivation/routes';
import RenewalTracker from 'pages/RenewalTracker/routes';
import ScoresFunction from 'pages/ScoresFunction/routes';
import UnderConstruction from 'pages/UnderConstruction/routes';
import NominationCoopSubmission from 'pages/NominationCoopSubmission/routes';
import NominationCoop from 'pages/NominationCoop/routes';

const routes = [
  Home,
  Login,
  MemberDetailFunction,
  InterviewFunction,
  ScoresFunction,
  HistoryNominatifFunction,
  HistoryDetailFunction,
  PengajuanUlangFunction,
  UnderConstruction,
  PengajuanUpgradingFunction,
  NominationFunction,
  MembersFunction,
  KabayanPart1Function,
  KabayanPart2Function,
  ...(FEATURE_REACTIVATION ? [PengajuanReactivation] : []),
  ...(FEATURE_SUBMISSION_CF ? [ContractFarming, ...ContractFarmingSubmission] : []),
  ...(FEATURE_RENEWAL_TRACKER ? [RenewalTracker] : []),
  HistoryNominatifRenewalFunction,
  HistoryDetailRenewalFunction,
  ...NominationCoopSubmission,
  NominationCoop,
];
export default routes;
