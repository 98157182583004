import { createContext } from 'react';
import useNominations from '../../hooks/useNominations';

export const NominationsContext = createContext({});

export const NominationsProvider = ({ children }) => {
  const { setNominationsContext, nominationsContext, setMultiFiltersContext, multiFilterContext } = useNominations();
  return (
    <NominationsContext.Provider
      value={{ setNominationsContext, nominationsContext, setMultiFiltersContext, multiFilterContext }}
    >
      {children}
    </NominationsContext.Provider>
  );
};
