import { useEffect, useState } from 'react';
import parseJwtPayload from 'utils/helpers/parseJwtPayload';
import { queryParams } from 'utils/helpers/queryParams';
import { sdkauth } from 'utils/libs/sdkCore';
import useGetToken from './useGetToken';
import { FEATURE_AUTH_COOKIE_KEYS } from 'utils/features';

const useAuthV2 = () => {
  const [userProfile, setUserProfile] = useState({});
  const { token, tokenNubitools } = useGetToken();
  const authGetProfileV2 = parseJwtPayload(token);
  const isAuthLoginV2 = (FEATURE_AUTH_COOKIE_KEYS ? tokenNubitools : token) || null;
  const [authLoginStatusV2, setLoginStatusV2] = useState('pending');
  const query = queryParams();

  const isSuperadmin = userProfile?.roles?.some(sublist => sublist.includes('nubitools_superadmin'));

  const authProfileV2 = async ({ code, grantType }) => {
    const response = await sdkauth.persistExchange(code, grantType);
    const user = await sdkauth.getProfile(response?.accessToken);

    if (FEATURE_AUTH_COOKIE_KEYS) await sdkauth.initializeCookieKeys();

    const userData = user?.data;
    setUserProfile(userData);

    return userData;
  };

  const authLoginV2 = async ({ code, grantType = 'authorization_code' }) => {
    try {
      const response = await authProfileV2({ code, grantType });
      if (response?.email) {
        setLoginStatusV2('resolved');
      }
      return {
        status: 'succeed',
      };
    } catch (error) {
      return {
        status: 'rejected',
      };
    }
  };

  const authLogoutV2 = async () => {
    const response = await sdkauth.logout();

    if (response.success) {
      window.location.href = '/login';
    }
  };

  useEffect(() => {
    if (authLoginStatusV2 === 'resolved') {
      window.location.href = `${query.get('ref') || '/'}`;
    }
  }, [authLoginStatusV2]);

  useEffect(() => {
    const fetchingProfile = async () => {
      const isLogin = await sdkauth.isSignedAsLogged();

      if (isLogin) {
        const data = await sdkauth.getProfile();
        setUserProfile(data?.data);
      }
    };

    fetchingProfile();
  }, []);

  return {
    isAuthLoginV2,
    authLoginStatusV2,
    authLoginV2,
    authLogoutV2,
    authGetProfileV2,
    isSuperadmin,
    userProfile,
  };
};

export default useAuthV2;
