import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const MemberDetailFunctionPages = loadable(() => import(/* webpackChunkName: "MemberPages" */ './index'));
const memberDetailFunctionRoute = {
  routeProps: {
    path: PATH.MEMBER_FUNCTION,
    component: MemberDetailFunctionPages,
  },
  isPublic: false,
  name: 'MemberDetailFunction',
};

export default memberDetailFunctionRoute;
