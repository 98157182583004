// Add route path here and sorting ascending a-z.
export const PATH = {
  HOME: '/',
  LOGIN: '/login',
  MEMBER_FUNCTION: '/members/:id?/:mode?',
  INTERVIEW_FUNCTION: '/interview/:id?/:mode?',
  SCORES_FUNCTION: '/scores2/:id',
  PENGAJUAN_ULANG: '/pengajuan-ulang/:id',
  PENGAJUAN_UPGRADING: '/pengajuan-upgrading/:id',
  HISTORY_NOMINATIF: '/history-nominatif/:id',
  HISTORY_DETAIL: '/history-detail/:id',
  UNDER_CONSTRUCTION: '/under-construction',
  NOMINATIONS: '/nominations',
  MEMBERS: '/list-members',
  KABAYAN_PART_1: '/kabayan-part-1',
  KABAYAN_PART_2: '/kabayan-part-2',
  PENGAJUAN_REACTIVATION: '/pengajuan-reaktivasi/:id',
  CONTRACT_FARMING: '/contract-farming',
  CONTRACT_FARMING_SUBMISSION: '/contract-farming/submission',
  CONTRACT_FARMING_SUBMISSION_EDIT: '/contract-farming/submission/:id',
  RENEWAL_TRACKER: '/renewal-tracker',
  HISTORY_NOMINATIF_RENEWAL: '/history-nominatif-renewal/:id',
  HISTORY_DETAIL_RENEWAL: '/history-detail-renewal/:id',
  NOMINATION_COOP_SUBMISSION: '/nomination-coop/submission',
  NOMINATION_COOP: '/nomination-coop',
};

export const PARAMS = {
  REDIRECT_URL: 'redirect_url',
};
