import { Badge } from 'reactstrap';

const BadgeSubmissionType = type => {
  const submissionTypes = {
    NEW: (
      <Badge color="success" pill>
        New
      </Badge>
    ),
    'RE-ASSIGN': (
      <Badge color="primary" pill>
        Re-Assign
      </Badge>
    ),
    'UPGRADING RE-ASSIGN': (
      <Badge color="info" pill>
        Upgrading Re-Assign
      </Badge>
    ),
    'RE-CLASS': (
      <Badge color="warning" pill>
        Re-Class
      </Badge>
    ),
    UPGRADING: (
      <Badge color="info" pill>
        Upgrading
      </Badge>
    ),
    IN_PROGRESS: (
      <Badge color="light" pill>
        In Progress
      </Badge>
    ),
    REJECTED: (
      <Badge color="danger" pill>
        Rejected
      </Badge>
    ),
    REACTIVATION: (
      <Badge pill style={{ background: '#e85dd1' }}>
        Reactivation
      </Badge>
    ),
    'REACTIVATION RE-ASSIGN': (
      <Badge pill style={{ background: '#e85dd1' }}>
        Reactivation Re-Assign
      </Badge>
    ),
    KBL: <Badge pill>Move to KBL</Badge>,
    KBR: <Badge pill>Move to KBR</Badge>,
    KBX: <Badge pill>Move to KBX</Badge>,
    KBJ: <Badge pill>Move to KBJ</Badge>,
  };
  return submissionTypes[type] ?? submissionTypes?.new;
};

export default BadgeSubmissionType;
