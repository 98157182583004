import React from 'react';
import { any } from 'prop-types';
import useSWR from 'swr';
import { toMappingEnvironments } from 'utils/libs/transformers/mapping';
import axios from 'axios';

const flagList = Object.entries(process.env).reduce((acc, curr) => {
  if (curr[0].endsWith('FLAG')) {
    const keyFlag = curr[0].replace('REACT_', '').replace('_FLAG', '');
    acc[keyFlag] = Number(curr[1]);
  }

  return acc;
}, {});

const fetcherV2 = (...args) => {
  return axios.post(...args, {
    entities: [
      {
        entityID: 'user',
        entityType: 'user',
        entityObject: {},
      },
    ],
    flagIDs: Object.values(flagList),
    enableDebug: false,
  });
};

export const FlagrContext = React.createContext({});

export const FlagrProvider = ({ children, ...props }) => {
  const variants = [];
  let mappingFlagrV2 = {};

  const { data } = useSWR(`${process.env.REACT_APP_FLAGR_V2_URL}/api/v1/evaluation/batch`, fetcherV2, {
    revalidateOnFocus: true,
  });
  const dataFlagrV2 = (data?.data?.evaluationResults || []).filter(item => item.variantKey === 'on');

  mappingFlagrV2 = Object.entries(flagList).reduce((acc, curr) => {
    const foundFlagr = dataFlagrV2.find(data => data.flagID === curr[1]);
    if (foundFlagr) {
      const key = curr[0];
      acc[key] = foundFlagr?.variantAttachment;
    }

    return acc;
  }, {});

  return (
    <FlagrContext.Provider
      value={process.env.REACT_APP_FLAGR_V2_URL ? mappingFlagrV2 : toMappingEnvironments(variants)}
      {...props}
    >
      {children}
    </FlagrContext.Provider>
  );
};

FlagrProvider.propTypes = {
  children: any.isRequired,
};
