import React from 'react';
import { any } from 'prop-types';
import { accessControlProvider } from './AccessProvider';

export const AccessContext = React.createContext({});

export const AccessProvider = ({ children, ...props }) => {
  const obj = accessControlProvider();

  return (
    <AccessContext.Provider value={obj} {...props}>
      {children}
    </AccessContext.Provider>
  );
};

AccessProvider.propTypes = {
  children: any.isRequired,
};
