import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const KabayanPart1FunctionPages = loadable(() => import(/* webpackChunkName: "InterviewPages" */ './index'));
const kabayanPart1FunctionRoute = {
  routeProps: {
    path: PATH.KABAYAN_PART_1,
    component: KabayanPart1FunctionPages,
  },
  isPublic: false,
  name: 'kabayan_part_1',
};

export default kabayanPart1FunctionRoute;
