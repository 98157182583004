import { differenceInMilliseconds } from 'date-fns';
import format from 'date-fns/format';

export const FormatDateSlice = date => format(date, 'dd/MM/yyyy');

export const FormatDateYear = date => format(date, 'yyyy-MM-dd');

export const FormatDateDesc = date => format(date, 'dd-MM-yyyy');

export const FormatDateTime = date => format(date, 'dd-MM-yyyy HH:mm:ss');

export const ConvertTimeFromSecond = second => {
  const hours = Math.floor(second / 3600);
  return second === null ? '-' : hours >= 24 ? `${(hours / 24).toFixed(2)} Hari` : `${hours < 1 ? '< 1' : hours} Jam`;
};

export const timeDifferenceInHour = (time1, time2) => {
  const diff = differenceInMilliseconds(time1, time2);
  return ConvertTimeFromSecond(diff / 1000);
};
