import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const ContractFarmingSubmissionPage = loadable(() =>
  import(/* webpackChunkName: "ContractFarmingSubmissionPage" */ './index'),
);
const contractFarmingSubmissionRoute = {
  routeProps: {
    exact: true,
    path: PATH.CONTRACT_FARMING_SUBMISSION,
    component: ContractFarmingSubmissionPage,
  },
  isPublic: false,
  name: 'ContractFarmingSubmission',
};

const contractFarmingSubmissionEditRoute = {
  routeProps: {
    exact: true,
    path: PATH.CONTRACT_FARMING_SUBMISSION_EDIT,
    component: ContractFarmingSubmissionPage,
  },
  isPublic: false,
  name: 'ContractFarmingSubmission',
};

export default [contractFarmingSubmissionRoute, contractFarmingSubmissionEditRoute];
