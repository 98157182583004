import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const ScoresFunctionPages = loadable(() => import(/* webpackChunkName: "ScoresPages" */ './index'));
const scoresFunctionRoute = {
  routeProps: {
    exact: true,
    path: PATH.SCORES_FUNCTION,
    component: ScoresFunctionPages,
  },
  isPublic: false,
  name: 'ScoresFunction',
};

export default scoresFunctionRoute;
