import React from 'react';
import { any } from 'prop-types';
import { Container, BannerApp, BannerContainer } from './style';
import '../../styles/globals.css';
import '../../styles/styleErrorBoundarie.css';
import '../../styles/FormInput.css';
import '../../styles/FormTemplate.css';
import '../../styles/Popup.css';
import '../../styles/Home.css';
import 'react-table/react-table.css';
import 'react-table-hoc-fixed-columns/lib/styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import SuperProviderGlobal from 'utils/context';
import SuperProviderHome from 'utils/context/HomeContext';

const AppContainer = ({ children }) => {
  return (
    <Container>
      <SuperProviderGlobal>
        <SuperProviderHome>
          {(process.env.REACT_APP_MODE === 'staging' || process.env.REACT_APP_MODE === 'UAT') && (
            <BannerContainer>
              <BannerApp>Environment {process.env.REACT_APP_MODE} </BannerApp>
            </BannerContainer>
          )}
          {children}
        </SuperProviderHome>
      </SuperProviderGlobal>
    </Container>
  );
};

AppContainer.propTypes = {
  children: any.isRequired,
};

export default AppContainer;
