import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const UnderConstructionPages = loadable(() => import(/* webpackChunkName: "UnderConstructionPages" */ './index'));
const underConstructionRoute = {
  routeProps: {
    path: PATH.UNDER_CONSTRUCTION,
    component: UnderConstructionPages,
  },
  isPublic: true,
  name: 'UnderConstruction',
};

export default underConstructionRoute;
