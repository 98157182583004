import { createContext } from 'react';
import useMembers1 from '../../hooks/useMembers1';

export const Members1Context = createContext({});

export const Members1Provider = ({ children }) => {
  const { setMembers1Context, members1Context } = useMembers1();
  return (
    <Members1Context.Provider value={{ setMembers1Context, members1Context }}>{children}</Members1Context.Provider>
  );
};
