import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const InterviewFunctionPages = loadable(() => import(/* webpackChunkName: "InterviewPages" */ './index'));
const interviewFunctionRoute = {
  routeProps: {
    path: PATH.INTERVIEW_FUNCTION,
    component: InterviewFunctionPages,
  },
  isPublic: false,
  name: 'InterviewFunction',
};

export default interviewFunctionRoute;
