import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const KabayanPart2FunctionPages = loadable(() => import(/* webpackChunkName: "InterviewPages" */ './index'));
const kabayanPart2FunctionRoute = {
  routeProps: {
    path: PATH.KABAYAN_PART_2,
    component: KabayanPart2FunctionPages,
  },
  isPublic: false,
  name: 'kabayan_part_2',
};

export default kabayanPart2FunctionRoute;
