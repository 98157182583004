import { useContext, useEffect, useState } from 'react';
import { AccessContext } from 'utils/context/AccessContext';

export const useCan = ({ action, resource, enabled = true }) => {
  const { can } = useContext(AccessContext);

  const [response, setResponse] = useState({ can: null });

  useEffect(async () => {
    if (!enabled) return;
    const queryResponse = await can({ action, resource });
    if (queryResponse) {
      setResponse(queryResponse);
    }
  }, [can]);

  return response;
};
