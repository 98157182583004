import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const MembersFunctionPages = loadable(() => import(/* webpackChunkName: "InterviewPages" */ './index'));
const membersFunctionRoute = {
  routeProps: {
    path: PATH.MEMBERS,
    component: MembersFunctionPages,
  },
  isPublic: false,
  name: 'member_list',
};

export default membersFunctionRoute;
