import { Popup } from 'components';
import { createContext } from 'react';
import usePopupV2 from '../hooks/usePopupV2';

export const PopupContext = createContext({});

export const PopupProvider = ({ children }) => {
  const { showPopup, hidePopup, catchPopup, properties, catchPopupError } = usePopupV2();

  return (
    <PopupContext.Provider value={{ showPopup, hidePopup, catchPopup, properties, catchPopupError }}>
      <Popup />
      {children}
    </PopupContext.Provider>
  );
};
