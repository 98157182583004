import { Badge } from 'reactstrap';

const BadgeStatus = type => {
  const status = {
    APPROVED: (
      <Badge color="success" pill>
        APPROVED
      </Badge>
    ),
    REJECTED: (
      <Badge color="danger" pill>
        REJECTED
      </Badge>
    ),
    IN_PROGRESS: (
      <Badge color="info" pill>
        IN PROGRESS
      </Badge>
    ),
  };
  return status[type] ?? status?.IN_PROGRESS;
};

export default BadgeStatus;
