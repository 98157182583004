import translator from 'utils/libs/translator';
import kabayanInterview from './kabayanInterview';
import member from './member';
import pengajuanUlang from './pengajuanUlang';
import pengajuanUpgrading from './pengajuanUpgrading';
import nominationCoopConstant from './nominationCoop';

const inputs = {
  feed_supplier_reputation: {
    name: 'feed_supplier_reputation',
    label: 'Reputasi di Supplier/Agen Pakan',
    options: [
      {
        value: 'Ada histori pembayaran atau reputasi yang buruk yang disampaikan oleh Agen/supplier/sales pakan',
        label: 'Ada histori pembayaran atau reputasi yang buruk yang disampaikan oleh Agen/supplier/sales pakan',
        score: 1,
      },
      {
        value: 'Tidak ada histori pembayaran atau reputasi yang buruk yang disampaikan oleh Agen/supplier/sales pakan',
        label: 'Tidak ada histori pembayaran atau reputasi yang buruk yang disampaikan oleh Agen/supplier/sales pakan',
        score: 3,
      },
    ],
  },
  keanggotaan_kelompok_tani: {
    name: 'keanggotaan_kelompok_tani',
    label: 'Keanggotaan Kelompok tani',
    options: [
      {
        value: 'Anggota',
        label: 'Anggota',
        score: 2,
      },
      {
        value: 'Koordinator/Ketua',
        label: 'Koordinator/Ketua',
        score: 3,
      },
    ],
  },
  seed_supplier_type: {
    name: 'seed_supplier_type',
    label: 'Tipe Supplier Benih',
    options: [
      {
        value: 'Tidak memiliki supplier benih yang tetap',
        label: 'Tidak memiliki supplier benih yang tetap',
        score: 1,
      },
      {
        value: 'Memiliki supplier benih tetap',
        label: 'Memiliki supplier benih tetap',
        score: 2,
      },
      {
        value: 'Memproduksi benih ikannya sendiri',
        label: 'Memproduksi benih ikannya sendiri',
        score: 3,
      },
    ],
  },
  tipe_supplier_pakan: {
    name: 'tipe_supplier_pakan',
    label: 'Tipe Supplier Pakan',
    options: [
      {
        value: 'Tidak memiliki supplier pakan yang tetap',
        label: 'Tidak memiliki supplier pakan yang tetap',
        score: 1,
      },
      {
        value: 'Memiliki supplier pakan tetap dari salah satu agen atau sub-agen',
        label: 'Memiliki supplier pakan tetap dari salah satu agen atau sub-agen',
        score: 2,
      },
      {
        value: 'Memiliki supplier pakan tetap dengan mengambil langsung dari pabrik',
        label: 'Memiliki supplier pakan tetap dengan mengambil langsung dari pabrik',
        score: 3,
      },
    ],
  },
  fish_buyer_type: {
    name: 'fish_buyer_type',
    label: 'Tipe Pembeli Ikan',
    options: [
      {
        value: 'Petani tidak memiliki pembeli ikan yang tetap dan rutin',
        label: 'Petani tidak memiliki pembeli ikan yang tetap dan rutin',
        score: 1,
      },
      {
        value: 'Petani memiliki pembeli ikan yang tetap dan rutin, tapi tidak dalam jumlah besar',
        label: 'Petani memiliki pembeli ikan yang tetap dan rutin, tapi tidak dalam jumlah besar',
        score: 2,
      },
      {
        value: 'Petani memiliki pembeli ikan yang tetap dan rutin, dalam jumlah yang besar',
        label: 'Petani memiliki pembeli ikan yang tetap dan rutin, dalam jumlah yang besar',
        score: 3,
      },
    ],
  },
  fish_buyer_payment_pattern: {
    name: 'fish_buyer_payment_pattern',
    label: 'Pola pembayaran pembeli',
    options: [
      {
        value: 'Seluruh pembeli/pelanggan ikan yang sekarang dimiliki, melakukan pembayaran mundur',
        label: 'Seluruh pembeli/pelanggan ikan yang sekarang dimiliki, melakukan pembayaran mundur',
        score: 1,
      },
      {
        value: 'Kurang dari 50% pembeli/pelanggan ikan yang sekarang dimiliki, melakukan pembayaran mundur',
        label: 'Kurang dari 50% pembeli/pelanggan ikan yang sekarang dimiliki, melakukan pembayaran mundur',
        score: 2,
      },
      {
        value: 'Seluruh pembeli/pelanggan ikan yang sekarang dimiliki, membayar tunai langsung',
        label: 'Seluruh pembeli/pelanggan ikan yang sekarang dimiliki, membayar tunai langsung',
        score: 3,
      },
    ],
  },
  buyer_receivables: {
    name: 'buyer_receivables',
    label: 'Piutang di pembeli',
    options: [
      {
        value: 'Petani memiliki piutang di pembeli ikan yang nilainya lebih besar dari pinjaman yang diminta',
        label: 'Petani memiliki piutang di pembeli ikan yang nilainya lebih besar dari pinjaman yang diminta',
        score: 1,
      },
      {
        value: 'Petani memiliki piutang di pembeli ikan yang nilainya sebesar 50% dari pinjaman yang diminta',
        label: 'Petani memiliki piutang di pembeli ikan yang nilainya sebesar 50% dari pinjaman yang diminta',
        score: 2,
      },
      {
        value: 'Petani tidak memiliki piutang di pembeli ikan',
        label: 'Petani tidak memiliki piutang di pembeli ikan',
        score: 3,
      },
    ],
  },
  // Persyaratan Dokumen
  foto_formulir: {
    name: 'foto_formulir',
    label: 'Formulir Permohonan Kabayan',
    accept: '.pdf,image/*',
  },

  foto_ktp_pasangan: {
    name: 'foto_ktp_pasangan',
    label: 'Foto KTP Pasangan',
    accept: '.pdf,image/*',
  },
  foto_kk: {
    name: 'foto_kk',
    label: 'Kartu Keluarga',
    accept: '.pdf,image/*',
  },
  foto_npwp: {
    name: 'foto_npwp',
    label: 'NPWP',
    accept: '.pdf,image/*',
  },
  foto_ktp_penjamin: {
    name: 'foto_ktp_penjamin',
    label: 'KTP Penjamin',
    accept: '.pdf,image/*',
  },
  foto_surat_kuasa: {
    name: 'foto_surat_kuasa',
    label: 'Surat Kuasa Petani',
    accept: '.pdf,image/*',
  },
  foto_kyc: {
    name: 'foto_kyc',
    label: 'Foto Selfie dg KTP',
    accept: '.pdf,image/*',
  },
  foto_pernyataan_npwp: {
    name: 'foto_pernyataan_npwp',
    label: 'Surat Pernyataan NPWP (jika tidak punya)',
    accept: '.pdf,image/*',
  },
  // Tanda tangan
  tanggal_form_diberikan: {
    name: 'tanggal_form_diberikan',
    label: 'Tanggal form diberikan ke petani',
    format: 'date',
  },
  tanggal_ttd: {
    name: 'tanggal_ttd',
    label: 'Tanggal Tanda Tangan',
    format: 'date',
  },
  area: {
    name: 'area',
    label: 'Area/Lokasi Point',
  },
  funder: {
    name: 'funder',
    label: 'Calon Funder',
  },
  // Data Pembudidaya
  nama_petani: {
    name: 'nama_petani',
    label: 'Nama Petani',
    translations: {
      label: {
        en: 'Farmer Name',
      },
    },
  },
  nama_panggilan: {
    name: 'nama_panggilan',
    label: 'Nama Panggilan',
    translations: {
      label: {
        en: 'Short Name',
      },
    },
  },
  jenis_kelamin: {
    name: 'jenis_kelamin',
    label: 'Jenis Kelamin',
    translations: {
      label: {
        en: 'Gender',
      },
    },
    options: ['Laki-laki', 'Perempuan'],
  },
  status_perkawinan: {
    name: 'status_perkawinan',
    label: 'Status ',
    translations: {
      label: {
        en: 'Married Status',
      },
    },
    options: ['Kawin', 'Belum Kawin', 'Duda/Janda'],
  },
  status_rumah: {
    name: 'status_rumah',
    label: 'Status Rumah',
    options: ['Milik Sendiri', 'Sewa/Kontrak', 'KPR', 'Milik Orangtua'],
  },
  tahun_menempati_rumah: {
    name: 'tahun_menempati_rumah',
    label: 'Lama Menempati (Tahun, Contoh: 2.5)',
    format: 'float',
  },
  alamat: {
    name: 'alamat',
    label: 'Alamat Lengkap',
    translations: {
      label: {
        en: 'Complete Address',
      },
    },
  },
  nama_gadis_ibu_kandung: {
    name: 'nama_gadis_ibu_kandung',
    label: 'Nama Gadis Ibu Kandung',
  },
  jumlah_motor: {
    name: 'jumlah_motor',
    format: 'int',
    label: 'Jumlah Motor yang dimiliki',
  },
  jumlah_mobil: {
    name: 'jumlah_mobil',
    format: 'int',
    label: 'Jumlah Mobil yang dimiliki',
  },
  nama_provinsi: {
    name: 'nama_provinsi',
    label: 'Provinsi',
  },
  province_id: {
    name: 'province_id',
    label: 'Provinsi',
  },
  nama_kota: {
    name: 'nama_kota',
    label: 'Kota/Kabupaten',
  },
  city_id: {
    name: 'city_id',
    label: 'Kota/Kabupaten',
  },
  nama_kecamatan: {
    name: 'nama_kecamatan',
    label: 'Kecamatan',
  },
  district_id: {
    name: 'district_id',
    label: 'Kecamatan',
  },
  no_ktp: {
    name: 'no_ktp',
    label: 'No KTP',
    format: 'ktp',
    translations: {
      label: {
        en: 'ID Number',
      },
    },
  },
  foto_ktp: {
    name: 'foto_ktp',
    label: 'Foto KTP',
    accept: '.pdf,image/*',
  },
  no_telp: {
    name: 'no_telp',
    label: 'No Telp',
    format: 'phone',
    translations: {
      label: {
        en: 'Phone Number',
      },
    },
  },
  // data pasangan
  nama_pasangan: {
    name: 'nama_pasangan',
    label: 'Nama Sesuai KTP/ Resi/ SIM',
  },
  no_ktp_pasangan: {
    name: 'no_ktp_pasangan',
    format: 'ktp',
    label: 'No Kartu Identitas ( KTP)',
  },
  id_tempat_lahir_pasangan: {
    name: 'id_tempat_lahir_pasangan',
    label: 'Tempat Lahir',
  },
  tanggal_lahir_pasangan: {
    name: 'tanggal_lahir_pasangan',
    label: 'Tanggal Lahir',
  },
  alamat_pasangan: {
    name: 'alamat_pasangan',
    label: 'Alamat',
  },
  id_provinsi_pasangan: {
    name: 'id_provinsi_pasangan',
    label: 'Provinsi',
  },
  id_kota_pasangan: {
    name: 'id_kota_pasangan',
    label: 'Kota / Kab',
  },
  id_kecamatan_pasangan: {
    name: 'id_kecamatan_pasangan',
    label: 'Kecamatan',
  },
  id_desa_pasangan: {
    name: 'id_desa_pasangan',
    label: 'Kelurahan/Desa',
  },
  no_telp_pasangan: {
    name: 'no_telp_pasangan',
    label: 'No HP',
    format: 'phone',
  },
  // Data darurat
  nama_orang_dalam_keadaan_darurat: {
    name: 'nama_orang_dalam_keadaan_darurat',
    label: 'Nama Sesuai KTP/ Resi/ SIM',
  },
  alamat_orang_dalam_keadaan_darurat: {
    name: 'alamat_orang_dalam_keadaan_darurat',
    label: 'Alamat',
  },
  no_telp_orang_dalam_keadaan_darurat: {
    name: 'no_telp_orang_dalam_keadaan_darurat',
    label: 'No HP',
    format: 'phone',
  },
  hubungan_orang_dalam_keadaan_darurat: {
    name: 'hubungan_orang_dalam_keadaan_darurat',
    label: 'Hubungan Dengan Pembudidaya',
  },
  // Budidaya dan usaha
  jenis_komoditas: {
    name: 'jenis_komoditas',
    label: 'Komoditas',
    translations: {
      label: {
        en: 'Comodity',
      },
    },
  },
  id_komoditas: {
    name: 'id_komoditas',
    label: 'Jenis Komoditas/ Ikan yang dibudidaya',
  },
  commodity_id: {
    name: 'commodity_id',
    label: 'Komoditas',
    translations: {
      label: {
        en: 'Comodity',
      },
    },
  },
  kepemilikan_kolam: {
    name: 'kepemilikan_kolam',
    label: 'Status Kepemilikan Kolam',
    options: ['Milik Sendiri', 'Sewa', 'Garapan/Bagi hasil'],
    translations: {
      label: {
        en: 'Ponds Ownership Status',
      },
      options: {
        en: {
          'Milik Sendiri': 'Self-owned',
          Sewa: 'Rent',
          Garapan: 'Labor',
        },
      },
    },
  },
  jumlah_kolam: {
    name: 'jumlah_kolam',
    label: 'Jumlah Kolam',
    format: 'int',
  },
  jumlah_kolam_pembiayaan: {
    name: 'jumlah_kolam_pembiayaan',
    label: 'Jumlah Kolam',
    format: 'int',
  },

  luas_kolam_pembiayaan: {
    name: 'luas_kolam_pembiayaan',
    label: 'Luas Kolam Aktif yang Diajukan Pembiayaan (m2)',
    format: 'float',
  },
  kapasitas_kolam_pembiayaan: {
    name: 'kapasitas_kolam_pembiayaan',
    label: 'Kapasitas Kolam Aktif yang Diajukan Pembiayaan (Kg)',
    format: 'float',
  },

  merek_pakan: {
    name: 'merek_pakan',
    label: 'Merek Pakan',
  },
  kg_per_sak_pakan: {
    name: 'kg_per_sak_pakan',
    label: 'Per Sak nya berapa Kg',
    format: 'float',
  },

  kolam_per_karyawan: {
    name: 'kolam_per_karyawan',
    label: 'Satu Karyawan Mengurus Berapa Kolam ',
    format: 'int',
  },
  biaya_produksi: {
    name: 'biaya_produksi',
    label: 'Biaya Produksi ',
    format: 'idr',
  },

  // Financing
  histori_pinjaman: {
    name: 'histori_pinjaman',
    label: 'Ada pinjaman kepada Pihak Ketiga?',
    options: ['Ya', 'Tidak'],
  },
  histori_pinjaman_institusi: {
    name: 'histori_pinjaman_institusi',
    label: 'Pinjaman Kepada',
    options: ['Bank', 'Koperasi', 'Institusi Pinjaman Lainnya', 'Perorangan'],
  },
  histori_pinjaman_nama: {
    name: 'histori_pinjaman_nama',
    label: 'Pinjaman Kepada (Nama)',
  },
  histori_pinjaman_nominal: {
    name: 'histori_pinjaman_nominal',
    label: 'Total Pinjaman',
    format: 'idr',
  },
  jumlah_kolam_aktif: {
    name: 'jumlah_kolam_aktif',
    label: 'Jumlah Kolam Aktif',
    format: 'int',
    translations: {
      label: {
        en: 'Active Ponds',
      },
    },
  },
  foto_kolam: {
    name: 'foto_kolam',
    label: 'Foto Kolam',
    accept: '.pdf,image/*',
  },
  latlong: {
    name: 'latlong',
    label: 'Google Maps (Latitude, Longitude)',
    format: 'latlong',
  },
  kebutuhan_pakan_kg: {
    name: 'kebutuhan_pakan_kg',
    label: 'Rataan Kebutuhan Pakan (kg) per Siklus per Kolam',
    format: 'float',
    translations: {
      label: {
        en: 'Average Feed Needed (Kg) / Cycle / Pond',
      },
    },
  },
  kebutuhan_pakan_sak: {
    name: 'kebutuhan_pakan_sak',
    label: 'Rataan Kebutuhan Pakan (Sak) per Siklus per Kolam',
    format: 'float',
    translations: {
      label: {
        en: 'Average Feed Needed (Sak) / Cycle / Pond',
      },
    },
  },
  harga_pakan_kg: {
    name: 'harga_pakan_kg',
    label: 'Rataan Harga Pakan per kg',
    format: 'idr',
    translations: {
      label: {
        en: 'Average Feed Price (IDR) / Kg',
      },
    },
  },
  harga_pakan_sak: {
    name: 'harga_pakan_sak',
    label: 'Harga Pakan per Sak',
    format: 'idr',
    translations: {
      label: {
        en: 'Average Feed Price (IDR) / Kg',
      },
    },
  },
  total_panen_kg: {
    name: 'total_panen_kg',
    label: 'Rataan Total Panen (kg) per Siklus per Kolam',
    format: 'float',
    translations: {
      label: {
        en: 'Average Total Harvest (Kg) / Cycle / Ponds',
      },
    },
  },
  harga_panen_kg: {
    name: 'harga_panen_kg',
    label: 'Rataan Harga Jual Panen per kg',
    format: 'idr',
    translations: {
      label: {
        en: 'Average Fish Selling Price (Kg)',
      },
    },
  },
  harga_panen_kg_tertinggi: {
    name: 'harga_panen_kg_tertinggi',
    label: 'Harga Panen Tertinggi (Harga jual ikan per kilo)',
    format: 'idr',
  },
  harga_panen_kg_terrendah: {
    name: 'harga_panen_kg_terrendah',
    label: 'Harga Panen Terrendah (Harga jual ikan per kilo)',
    format: 'idr',
  },
  nominal_pengajuan: {
    name: 'nominal_pengajuan',
    label: 'Estimasi Pengajuan (Rp)',
    format: 'idr',
    translations: {
      label: {
        en: 'Credit Limit (IDR)',
      },
    },
  },
  tanggal_butuh: {
    name: 'tanggal_butuh',
    label: 'Perkiraan tanggal butuh dana',
    maxDate: null,
  },
  tempat_lahir: {
    name: 'tempat_lahir',
    label: 'Tempat Lahir',
  },
  id_tempat_lahir: {
    name: 'id_tempat_lahir',
    label: 'Tempat Lahir',
  },
  tanggal_lahir: {
    name: 'tanggal_lahir',
    label: 'Tanggal Lahir',
    format: 'date',
  },
  sub_district_id: {
    name: 'sub_district_id',
    label: 'Desa',
  },
  nama_desa: {
    name: 'nama_desa',
    label: 'Desa',
  },
  kelompok_tani: {
    name: 'kelompok_tani',
    label: 'Nama Usaha/Kelompok',
  },
  no_npwp: {
    name: 'no_npwp',
    label: 'No NPWP',
    format: 'int',
  },
  bank_rek: {
    name: 'bank_rek',
    label: 'Nama Bank',
  },
  no_rek: {
    name: 'no_rek',
    label: 'Nomor Rekening',
    format: 'int',
  },
  nama_rek: {
    name: 'nama_rek',
    label: 'Nama Pemilik Rekening',
  },
  email: {
    name: 'email',
    label: 'Email',
  },
  inti_individual: {
    name: 'inti_individual',
    label: 'Tipe Mitra',
    options: ['Inti-Plasma', 'Individual', 'Perusahaan'],
  },
  folder_petani: {
    name: 'folder_petani',
    label: 'Link Folder Petani',
  },
  tanggal_input: {
    name: 'tanggal_input',
    label: 'Tanggal Input',
  },
  kunjungan_pertama: {
    name: 'kunjungan_pertama',
    label: 'Kunjungan Pertama',
  },
  sales: {
    name: 'sales',
    label: 'Nama Sales',
  },
  nama_ahli_waris: {
    name: 'nama_ahli_waris',
    label: 'Nama Lengkap',
  },
  tempat_lahir_ahli_waris: {
    name: 'tempat_lahir_ahli_waris',
    label: 'Tempat Lahir',
  },
  tanggal_lahir_ahli_waris: {
    name: 'tanggal_lahir_ahli_waris',
    label: 'Tanggal Lahir',
    format: 'date',
  },
  alamat_ahli_waris: {
    name: 'alamat_ahli_waris',
    label: 'Alamat Lengkap',
  },
  no_telp_ahli_waris: {
    name: 'no_telp_ahli_waris',
    label: 'No Telp',
    format: 'int',
  },
  hubungan_ahli_waris: {
    name: 'hubungan_ahli_waris',
    label: 'Status Hubungan',
  },
  pengajuan_pakan: {
    name: 'pengajuan_pakan',
    label: 'Pengajuan Pakan (kg)',
    format: 'float',
  },
  produk_finansial: {
    name: 'produk_finansial',
    label: 'Produk Finansial',
    options: ['Perdagangan', 'Jelang Panen', 'Siklus'],
  },
  tenor_bulan: {
    name: 'tenor_bulan',
    label: 'Tenor (bulan)',
    format: 'int',
  },
  skema_pinjaman: {
    name: 'skema_pinjaman',
    label: 'Skema Pinjaman',
    options: ['Interest', 'Bagi Hasil'],
  },
  financial_gap: {
    name: 'financial_gap',
    label: 'Financial Gap',
  },
  nama_analis: {
    name: 'nama_analis',
    label: 'Nama Analis',
  },
  interview_date: {
    name: 'interview_date',
    label: 'Tanggal Interview',
  },
  jumlah_kolam_diajukan: {
    name: 'jumlah_kolam_diajukan',
    label: 'Jumlah Kolam Diajukan',
    format: 'int',
  },
  jumlah_kolam_dimiliki: {
    name: 'jumlah_kolam_dimiliki',
    label: 'Jumlah Kolam Dimiliki',
    format: 'int',
  },
  jumlah_kolam_setahun_lalu: {
    name: 'jumlah_kolam_setahun_lalu',
    label: 'Jumlah Kolam Setahun Lalu',
    format: 'int',
  },
  pond_type: {
    name: 'pond_type',
    label: 'Tipe Kolam',
    options: ['Tanah', 'Tanah dengan lapisan terpal atau plastik', 'Semen, HDPE'],
  },
  rent_pond_per_month: {
    name: 'rent_pond_per_month',
    label: 'Biaya Sewa Kolam per bulan (Rp)',
    format: 'idr',
  },
  seed_dispersal_per_pond: {
    name: 'seed_dispersal_per_pond',
    label: 'Jumlah Tebar per Kolam (ekor)',
    format: 'int',
  },
  tanggal_tebar: {
    name: 'tanggal_tebar',
    label: 'Perkiraan Tanggal Tebar',
  },
  harvest_size_per_kg: {
    name: 'harvest_size_per_kg',
    label: 'Ukuran Panen (ekor per kg)',
    format: 'float',
  },
  hpp_per_kolam: {
    name: 'hpp_per_kolam',
    label: 'Rata-rata HPP/Kg (Rp)',
    format: 'idr',
  },
  revenue_kolam: {
    name: 'revenue_kolam',
    label: 'Revenue per kolam (Rp)',
    format: 'idr',
  },
  gross_margin_kolam: {
    name: 'gross_margin_kolam',
    label: 'Gross Margin per kolam (Rp)',
    format: 'idr',
  },
  sr_in_percent: {
    name: 'sr_in_percent',
    label: 'SR (%)',
    format: 'float',
  },
  do: {
    name: 'do',
    label: 'DO (mg/L)',
    format: 'float',
  },
  ph: {
    name: 'ph',
    label: 'pH',
    format: 'float',
  },
  jenis_pakan_terapung_tenggelam: {
    name: 'jenis_pakan',
    label: 'Jenis Pakan',
    options: ['Terapung', 'Tenggelam', 'Terapung dan Tenggelam'],
  },
  feed_type: {
    name: 'feed_type',
    label: 'Pakan yang digunakan',
    options: {
      'Hanya Pelet': 'Hanya Pakan Pelet Pabrik',
      'Hanya Alternatif': 'Hanya Alternatif (Limbah, Tanaman, Maggot, Giling, dsj)',
      'Campuran Pelet dan Alternatif': 'Campuran Pelet dan Alternatif',
    },
  },
  merk_pakan: {
    name: 'merk_pakan',
    label: 'Merk Pakan',
  },
  varian_pakan: {
    name: 'varian_pakan',
    label: 'Varian Pakan',
  },
  sistem_beli_pakan: {
    name: 'sistem_beli_pakan',
    label: 'Sistem Pembelian Pakan',
    options: ['Tunai', 'Cicilan', 'Tunggu Akhir Panen', 'others'],
  },
  harga_benih_liter: {
    name: 'harga_benih_liter',
    label: 'Harga Benih per liter',
    format: 'idr',
  },
  seed_price_per_kg: {
    name: 'seed_price_per_kg',
    label: 'Harga Benih per KG',
    format: 'idr',
  },
  number_of_seeds_per_kg: {
    name: 'number_of_seeds_per_kg',
    label: 'Jumlah Ekor per KG',
    format: 'int',
  },
  lama_budidaya_hari: {
    name: 'lama_budidaya_hari',
    label: 'Lama budidaya (hari), asumsi 1 bulan = 30 hari',
    format: 'int',
  },
  harvest_frequency_per_month: {
    name: 'harvest_frequency_per_month',
    label: 'Frekuensi panen per Bulan',
    format: 'float',
  },
  cultivation_cycle_per_year: {
    name: 'cultivation_cycle_per_year',
    label: 'Siklus Tanam per Tahun',
    format: 'float',
  },
  pengalaman_budidaya_tahun: {
    name: 'pengalaman_budidaya_tahun',
    label: 'Pengalaman Budidaya (tahun, bisa koma)',
    format: 'float',
    translations: {
      label: {
        en: 'Farming Experience (years)',
      },
    },
  },
  cultivation_note: {
    name: 'cultivation_note',
    label: 'Pencatatan Budidaya',
    options: [
      'Tidak mencatat data budidaya',
      'Mencatat data budidaya, tapi hanya tersedia di 1 siklus terakhir',
      'Mencatat data budidaya dan tersedia di lebih dari 1 siklus sebelumnya',
    ],
  },
  cultivation_procedure: {
    name: 'cultivation_procedure',
    label: 'Prosedur Budidaya',
    options: [
      'Tidak memiliki prosedur dalam budidaya',
      'Memiliki SOP, tapi tidak tertulis',
      'Memiliki SOP yang tertulis di dalam dokumen dan bentuk lainnya (papan tulis,signage, etc)',
    ],
  },
  saprodi_tambahan: {
    name: 'saprodi_tambahan',
    label: 'Penerapan Saprodi Tambahan',
  },
  jumlah_karyawan: {
    name: 'jumlah_karyawan',
    label: 'Jumlah Karyawan',
    format: 'int',
  },
  gaji_karyawan_bulan: {
    name: 'gaji_karyawan_bulan',
    label: 'Gaji per Karyawan per Bulan',
    format: 'idr',
  },
  gaji_karyawan_siklus: {
    name: 'gaji_karyawan_siklus',
    label: 'Total Gaji Karyawan per Siklus per Kolam',
    format: 'idr',
  },
  biaya_panen_kolam: {
    name: 'biaya_panen_kolam',
    label: 'Biaya Panen per Kolam',
    format: 'idr',
  },
  biaya_logistik_kolam: {
    name: 'biaya_logistik_kolam',
    label: 'Biaya Logistik per Kolam',
    format: 'idr',
  },
  harga_jual_tertinggi: {
    name: 'harga_jual_tertinggi',
    label: 'Harga Jual Tertinggi/Kg (Rp)',
    format: 'idr',
  },
  harga_jual_termurah: {
    name: 'harga_jual_termurah',
    label: 'Harga Jual Terendah/Kg (Rp)',
    format: 'idr',
  },
  keuntungan_siklus: {
    name: 'keuntungan_siklus',
    label: 'Rataan Keuntungan per Siklus',
    format: 'idr',
  },
  revenue_estimation_this_year: {
    name: 'revenue_estimation_this_year',
    label: 'Estimasi Revenue Tahun Ini',
    format: 'idr',
  },
  revenue_last_year: {
    name: 'revenue_last_year',
    label: 'Revenue Tahun Lalu',
    format: 'idr',
  },
  feed_supplier_name: {
    name: 'feed_supplier_name',
    label: 'Nama Supplier Pakan',
  },
  feed_supplier_contact: {
    name: 'feed_supplier_contact',
    label: 'No Kontak Supplier Pakan',
    format: 'int',
  },
  feed_supplier_address: {
    name: 'feed_supplier_address',
    label: 'Alamat Supplier Pakan',
  },
  seed_supplier_name: {
    name: 'seed_supplier_name',
    label: 'Nama Supplier Benih',
  },
  seed_supplier_contact: {
    name: 'seed_supplier_contact',
    label: 'No Kontak Supplier Benih',
    format: 'int',
  },
  seed_supplier_address: {
    name: 'seed_supplier_address',
    label: 'Alamat Supplier Benih',
  },
  fish_buyer_name: {
    name: 'fish_buyer_name',
    label: 'Nama Pembeli Ikan',
  },
  fish_buyer_contact: {
    name: 'fish_buyer_contact',
    label: 'No Kontak Pembeli Ikan',
    format: 'int',
  },
  fish_buyer_address: {
    name: 'fish_buyer_address',
    label: 'Alamat Pembeli Ikan',
  },
  jumlah_pembayaran_mundur: {
    name: 'jumlah_pembayaran_mundur',
    label: 'Berapa yang mundur?',
  },
  jumlah_piutang_di_pembeli: {
    name: 'jumlah_piutang_di_pembeli',
    label: 'Berapa jumlah piutang di pembeli?',
  },
  reputasi_buruk_di_supplier: {
    name: 'reputasi_buruk_di_supplier',
    label: 'Penjelasan Reputasi Buruk',
  },
  simulasi_siklus: {
    name: 'simulasi_siklus',
    label: 'Simulasi/History Hasil Panen Sebelumnya',
  },
  environmental_conditions: {
    name: 'environmental_conditions',
    label: 'Kondisi Alam',
  },
  location_water_sources_distance_in_m: {
    name: 'location_water_sources_distance_in_m',
    label: 'Jarak ke Sumber Air (m)',
    format: 'float',
  },
  /*
    =============
    =============
    input emart
    =============
    =============
  */
  /*
    ============
    supply area
    ============
  */
  panen_tanggal_awal: {
    name: 'panen_tanggal_awal',
    label: 'Panen Tanggal Awal',
    format: 'date',
  },
  petani_nama: {
    name: 'petani_nama',
    label: 'Petani Nama',
  },
  panen_size_est: {
    name: 'panen_size_est',
    label: 'Size Panen',
  },
  panen_size_type: {
    name: 'panen_size_type',
    label: 'Panen Size Tipe',
    options: ['ekor_kg', 'kg_ekor'],
  },
  panen_volume_est_kg: {
    name: 'panen_volume_est_kg',
    label: 'Panen Volume Est (KG)',
  },
  kolam_lokasi: {
    name: 'kolam_lokasi',
    label: 'Kolam Lokasi',
  },
  kolam_harga_mati: {
    name: 'kolam_harga_mati',
    label: 'Kolam Harga Mati',
    format: 'float',
  },
  kolam_harga_hidup: {
    name: 'kolam_harga_hidup',
    label: 'Kolam Harga Hidup',
    format: 'float',
  },
  jenis_kolam: {
    name: 'jenis_kolam',
    label: 'Jenis Kolam',
    options: ['Tanah', 'Tanah dengan lapisan terpal atau plastik', 'Semen, HDPE'],
  },
  kondisi: {
    name: 'kondisi',
    label: 'Kondisi Komoditas',
    options: ['hidup', 'fillet', 'mati fresh es', 'Bersih sisik dan jeroan', 'Bersih jeroan', 'lepas kepala'],
  },
  komoditas: {
    name: 'komoditas',
    label: 'Jenis Komoditas',
  },
  deskripsi: {
    name: 'deskripsi',
    label: 'Deskripsi',
  },
  /*
  ==============
    input order
  ==============
  */
  total_kg: {
    name: 'total_kg',
    label: 'Total (Kg)',
    format: 'float',
  },
  status_order: {
    name: 'status_order',
    label: 'Status Order',
    options: [
      {
        value: 'requested',
        label: 'sedang mencocokkan',
      },
      {
        value: 'confirmed',
        label: 'menunggu pengiriman',
      },
      {
        value: 'delivering',
        label: 'sedang dikirim',
      },
      {
        value: 'finished',
        label: 'sudah diterima',
      },
    ],
  },
  order_sample: {
    name: 'order_sample',
    label: 'Order Sample',
  },
  kontak_nomor: {
    name: 'kontak_nomor',
    label: 'Kontak Nomor',
  },
  kontak_wa: {
    name: 'kontak_wa',
    label: 'Kontak WA',
  },
  kontak_nama: {
    name: 'kontak_nama',
    label: 'Kontak Nama',
  },
  kontak_restoran: {
    name: 'kontak_restoran',
    label: 'Kontak Restoran',
  },
  penerima_nomor: {
    name: 'penerima_nomor',
    label: 'Penerima Nomor',
  },
  penerima_wa: {
    name: 'penerima_wa',
    label: 'Penerima WA',
  },
  penerima_nama: {
    name: 'penerima_nama',
    label: 'Penerima Nama',
  },
  penerima_kel: {
    name: 'penerima_kel',
    label: 'Penerima Kelurahan',
  },
  penerima_subdistrict_id: {
    name: 'penerima_subdistrict_id',
    label: 'Penerima Subdiscrict ID',
  },
  penerima_alamat: {
    name: 'penerima_alamat',
    label: 'Penerima Alamat',
  },
  kirim_durasi: {
    name: 'kirim_durasi',
    label: 'Kirim Durasi',
    format: 'float',
  },
  kirim_waktu: {
    name: 'kirim_waktu',
    label: 'Kirim waktu',
  },
  kirim_expected: {
    name: 'kirim_expected',
    label: 'Kirim expected',
  },
  kirim_biaya_kg: {
    name: 'kirim_biaya_kg',
    label: 'Kirim biaya kg',
  },
  kirim_rutin_checked: {
    name: 'kirim_rutin_checked',
    label: 'Kirim rutin checked',
  },
  kirim_rutin_freq: {
    name: 'kirim_rutin_freq',
    label: 'Kirim rutin freq',
  },
  kirim_orderid_prev: {
    name: 'kirim_orderid_prev',
    label: 'kirim orderid prev',
  },
  invoice_pesanan_rp: {
    name: 'invoice_pesanan_rp',
    label: 'Invoice pesanan rp',
    format: 'idr',
  },
  invoice_kirim_rp: {
    name: 'invoice_kirim_rp',
    label: 'invoice kirim rp',
    format: 'idr',
  },
  invoice_total_rp: {
    name: 'invoice_total_rp',
    label: 'invoice total rp',
    format: 'idr',
  },
  penerima_subdisctrict_id: {
    name: 'penerima_subdisctrict_id',
    label: 'penerima subdisctrict id',
  },
  url_location_wa: {
    name: 'url_location_wa',
    label: 'URL GMaps Location Penerima',
  },
  ...member,
  ...kabayanInterview,
  ...pengajuanUlang,
  ...pengajuanUpgrading,
  ...nominationCoopConstant,
};

// eslint-disable-next-line no-unused-vars
for (const input of Object.values(inputs)) {
  if (input.options) {
    // create score getter
    input.getScore = value => {
      // eslint-disable-next-line no-unused-vars
      for (const option of getOptions(input)) {
        if (option.value === value) {
          return option.score;
        }
      }
    };

    // create value label getter
    input.getValueLabel = value => {
      // eslint-disable-next-line no-unused-vars
      for (const option of getOptions(input)) {
        if (option.value === value) {
          return option.label;
        }
      }
    };
  }

  // attach translations getter
  translator.attachObjectTranslator(input);
}

const getOptions = input => {
  let options = [];
  if (Array.isArray(input.options)) {
    options = input.options.map(item => {
      if (typeof item === 'string') {
        return { value: item, label: item };
      } else {
        return item;
      }
    });
  } else {
    options = Object.entries(input.options).map(([value, label]) => ({
      value,
      label,
    }));
  }
  return options;
};

export default inputs;
