const pengajuanUlangConstant = {
  filter_nomination: [
    'lead_id',
    'name',
    'phone',
    'ktp_number',
    'point_name',
    'requested_at',
    'kabayan_type_code',
    'nomination_code',
    'progress_id',
    'status',
    'pic_name',
    'pic_email',
    'submission_type',
  ],
  title_filter_nomination: {
    nomination_code: 'Kode Pengajuan',
    progress_id: 'Progress Pengajuan',
    status: 'Status Pengajuan',
  },
  kabayan: {
    KBR: '1',
    KBX: '2',
    KBL: '3',
    KBJ: '4',
  },
  kabayan_code: {
    1: 'KBR',
    2: 'KBX',
    3: 'KBL',
    4: 'KBJ',
  },
  reasons: {
    REJECT_CALL: '1',
    REJECT_INTERNAL_DOCUMENT: '2',
    REJECT_INTERNAL_DATA: '3',
    PKS_EXPIRED: '4',
  },
  kabayan_type: {
    name: 'kabayan_type_id',
    label: 'Tipe Kabayan',
    required: true,
  },
  kabayan_type_new: {
    name: 'kabayan_type_id',
    required: true,
  },
  reassign_reason: {
    name: 'reassign_reason_id',
    label: 'Alasan Pengajuan Ulang',
    required: true,
  },
  is_phone_changed: {
    name: 'is_phone_changed',
    label: 'Apakah terdapat perubahan No. Handphone yang terdaftar di eFisheryku atau Nubitools?',
    options: ['Ya', 'Tidak'],
    required: true,
  },
  phone_changed_evidence: {
    name: 'phone_changed_evidence',
    label: 'Jika terdapat perubahan mohon dilampirkan screenshoot atas laporan di Bug Report',
    accept: '.pdf,image/*',
    required: true,
  },
  farmer_available_call: {
    name: ['farmer_available_call', 'farmer_available_call_2'],
    label: 'Waktu Petani Bisa di telpon/kunjungi',
    description: 'Jam telepon mengikuti jam kerja yaitu 09.00 s.d 18.00',
    required: true,
  },
  id_card_image: {
    name: 'id_card_image',
    label: 'KTP Petani',
    accept: '.pdf,image/*',
  },
  id_card_spouse_image: {
    name: 'id_card_spouse_image',
    label: 'KTP Pasangan Petani',
    accept: '.pdf,image/*',
  },
  family_card_image: {
    name: 'family_card_image',
    label: 'Kartu Keluarga',
    accept: '.pdf,image/*',
  },
  selfie_image: {
    name: 'selfie_image',
    label: 'Swafoto',
    accept: '.pdf,image/*',
  },
  pond_image: {
    name: 'pond_image',
    label: 'Foto Kolam Keseluruhan',
    accept: '.pdf,image/*',
  },
  pond_other_image: {
    name: 'pond_other_image',
    label: 'Foto Kolam Lainnya',
    accept: '.pdf,image/*',
  },
  house_image: {
    name: 'house_image',
    label: 'Foto Rumah',
    accept: '.pdf,image/*',
  },
  detail_rejection: {
    name: 'detail_rejection',
    label: 'Detail Penolakan',
    required: true,
  },
  cultivation_update: {
    name: 'cultivation_update',
    label: 'Update Data Budidaya',
    required: true,
  },
  commodity: {
    name: 'commodity_id',
    label: 'Komoditas',
    required: true,
  },
  checklist_uploaded_files: {
    name: 'checklist_uploaded_files',
    label: 'Checklist Dokumen yang di Upload',
    required: false,
  },
  checklist_updated_data: {
    name: 'checklist_updated_data',
    label: 'Checklist Data yang di Update',
    required: false,
  },
  document_update_nubitools: {
    name: 'document_update_nubitools',
    label: 'Apakah data & dokumen sudah terupdate di Nubitools?',
    description:
      'Jika data & dokumen belum terupdate/tidak sesuai maka pengajuan akan di tolak kembali. Harap Pastikan sudah terupdate!!',
    options: ['Ya'],
    required: true,
  },
};

export default pengajuanUlangConstant;
