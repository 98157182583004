// Global styles get added in the wrong order when using styled-components.
// Exporting the styles from this file is the current workaround to this issue.
// https://github.com/ben-rogerson/twin.macro/issues/277#issuecomment-754240911
import { createGlobalStyle } from 'styled-components';
import {
  BLACK_100,
  BLACK_200,
  BLACK_300,
  BLACK_400,
  BLACK_50,
  BLACK_500,
  BLUE_400,
  BLUE_800,
  BLUE_900,
  OBSERVATORY_100,
  OBSERVATORY_200,
  OBSERVATORY_400,
  OBSERVATORY_500,
  OBSERVATORY_600,
  RED_100,
  RED_200,
  RED_500,
  WHITE_COLOR,
} from 'utils/styles/colors';

const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  *, :after, :before {
    box-sizing: border-box;
    border: 0 solid;
  }

 
  body, html {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-size: 16px;
    color:  ${BLACK_500};
    letter-spacing: 0.5px;
  }

  body {
    margin: auto;
    max-height: 1440px;
    width: 100%;
  }

  body.modal-open iframe {
    z-index: 0 !important;
    display: none;
  }

  @media (max-width: 480px) {
    body {
      width: 100%;
      min-width: 360px;
    }
  }

  #root {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    text-align: left;
  }

  input {
    color:  ${BLACK_500};
  }

  a {
    text-decoration: none;
    color: ${OBSERVATORY_500};
  }

  a:hover {
    color: ${OBSERVATORY_600};
    text-decoration: none;
  }

  label {
    display: inline-block;
  }

  button {
    padding: 0px;
    letter-spacing: 0.5px;
  }


  .confirmCustom .confirmCustom-buttons {
  display: flex;
  flex-direction: column;
}

.confirmCustom .confirmCustom-content {
  padding: 24px !important;
}

.confirmCustom-head > h5 {
  display: none;
}

.confirmCustom-head > div {
  text-align: left !important;
  padding: 0px !important;
  line-height: inherit !important;
  margin: 0px 0px 24px 0px !important;
}

.confirmCustom .confirmCustom-buttons > a {
  width: 100% !important;
  padding: 11px !important;
  line-height: 1.5rem !important;
  border-color: rgba(3, 135, 103, var(--tw-border-opacity));
  border-style: solid;
  border-width: 1px;
  margin: 0px !important;
  margin-bottom: 14px !important;
}

.notifModal > div.notifModal-content {
  padding: 18px !important;
}

.notifModal > .notifModal-content > .notifModal-title {
  border-bottom: none;
  margin: 0 0 20px;
  padding: 0px;
  margin-top: -15px;
}

.notifModal > .notifModal-content > .notifModal-message {
  padding: 0px;
}

.notifModal > .notifModal-content > .notifModal-title {
  text-align: left;
}

.notifModal > .notifModal-content > .notifModal-button {
  position: absolute;
  top: 8px;
  right: 0px;
  background: transparent !important;
  color: black !important;
}

.Modal-Portal .datepicker-modal {
  color: ${OBSERVATORY_500};
  position: fixed;
}

.Modal-Portal .datepicker-modal > .datepicker {
  max-width: 1440px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.datepicker-modal > .datepicker.default .datepicker-header {
  color: ${OBSERVATORY_500};
  padding: 10px 20px;
  text-align: left;
  font-weight: 500;
  border-bottom: 2px solid ${OBSERVATORY_500};
}

.datepicker-modal > .datepicker.default .datepicker-navbar-btn {
  color: ${OBSERVATORY_500};
}

.datepicker-modal > .datepicker.default .datepicker-wheel {
  border-top: 2px solid ${OBSERVATORY_500};
  border-bottom: 2px solid ${OBSERVATORY_500};
}

.datepicker-modal > .datepicker .datepicker-scroll>li {
  font-size: 0.875rem;
  color: ${BLACK_500};
}

.btn-fill-primary{
  border-radius: 8px;
  padding: 5px 16px;
  background-color: ${OBSERVATORY_500};
  color: ${WHITE_COLOR};
  outline: none;
  box-shadow:none !important;
  border-color: ${OBSERVATORY_500};
}

.btn-fill-primary:hover{
  background-color: ${OBSERVATORY_600};
  color: ${WHITE_COLOR} !important;
  border-color: ${OBSERVATORY_500};
}

.btn-fill-primary:disabled{
  background-color: ${BLACK_50};
  color: ${BLACK_200} !important;
  border-color: ${BLACK_50};
}

.btn-fill-primary:active:focus{
  background-color: ${OBSERVATORY_400} !important;
  color: ${WHITE_COLOR} !important;
  outline: none;
  box-shadow:none !important;
  border-color: ${OBSERVATORY_500} !important;
}

.btn-fill-secondary {
  border-radius: 8px;
  padding: 5px 16px;
  background-color: ${BLACK_200};
  color: ${WHITE_COLOR};
  outline: none;
  box-shadow:none !important;
  border-color: ${BLACK_200};
}

.btn-fill-secondary:hover{
  background-color: ${BLACK_300};
  color: ${WHITE_COLOR} !important;
  border-color: ${BLACK_300};
}

.btn-fill-secondary:disabled{
  background-color: ${BLACK_50};
  color: ${BLACK_200} !important;
  border-color: ${BLACK_50};
}

.btn-fill-secondary:active:focus{
  background-color: ${BLACK_400} !important;
  color: ${WHITE_COLOR} !important;
  outline: none;
  box-shadow:none !important;
  border-color: ${BLACK_400} !important;
}

.btn-fill-info{
  border-radius: 8px;
  padding: 5px 16px;
  background-color: ${BLUE_800};
  color: ${WHITE_COLOR};
  outline: none;
  box-shadow:none !important;
  border-color: ${BLUE_800};
}

.btn-fill-info:hover{
  color: ${WHITE_COLOR} !important;
  background-color: ${BLUE_900};
}

.btn-fill-info:disabled{
  background-color: ${BLACK_50};
  color: ${BLACK_200} !important;
  border-color: ${BLACK_50};
}

.btn-fill-info:active:focus{
  background-color: ${BLUE_400} !important;
  color: ${WHITE_COLOR} !important;
  outline: none;
  box-shadow:none !important;
  border-color: ${BLUE_800} !important;
}

.btn-outline-primary{
  border-radius: 8px;
  padding: 5px 10px;
  background-color: ${WHITE_COLOR};
  color: ${OBSERVATORY_500};
  outline: none;
  box-shadow:none !important;
  border-color: ${OBSERVATORY_500};
}

.btn-outline-primary:hover{
  background-color: ${OBSERVATORY_100};
  color: ${OBSERVATORY_500};
  border-color: ${OBSERVATORY_500};
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: ${BLACK_300};
  border-color: ${BLACK_300};
}

.btn-outline-primary.disabled:hover {
  color: ${BLACK_300};
  background-color: ${BLACK_50};
}

.btn-outline-primary:active:focus{
  background-color: ${OBSERVATORY_200} !important;
  color: ${OBSERVATORY_500} !important;
  outline: none;
  box-shadow:none !important;
  border-color: ${OBSERVATORY_500} !important;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle{
  background-color: ${OBSERVATORY_100};
  color: ${OBSERVATORY_500} !important;
  border-color: ${OBSERVATORY_500};
}

.btn-outline-danger{
  border-radius: 8px;
  padding: 5px 10px;
  background-color: ${WHITE_COLOR};
  color: ${RED_500};
  outline: none;
  box-shadow:none !important;
  border-color: ${RED_500};
}

.btn-outline-danger:hover{
  background-color: ${RED_100};
  color: ${RED_500} !important;
  border-color: ${RED_500};
}

.btn-outline-danger:disabled{
  background-color: ${WHITE_COLOR};
  color: ${RED_100} !important;
  border-color: ${RED_100};
}

.btn-outline-danger:active:focus{
  background-color: ${RED_200} !important;
  color: ${RED_500} !important;
  outline: none;
  box-shadow:none !important;
  border-color: ${RED_500} !important;
}

.btn-outline-secondary{
  border-radius: 8px;
  padding: 5px 10px;
  background-color: ${WHITE_COLOR};
  color: ${BLACK_200};
  outline: none;
  box-shadow:none !important;
  border-color: ${BLACK_200};
}

.btn-outline-secondary:hover{
  background-color: #F7F7F7;
  color: ${BLACK_200} !important;
  border-color: ${BLACK_200};
}

.btn-outline-secondary:active:focus{
  background-color: ${BLACK_100} !important;
  color: ${BLACK_200} !important;
  outline: none;
  box-shadow:none !important;
  border-color: ${BLACK_200} !important;
}

.btn-link {
  text-decoration: none !important;
  outline: none !important;
  box-shadow:none !important;
}

.btn-outline-off{
  background-color: ${WHITE_COLOR} !important;
  color: ${BLACK_200} !important;
  border-color: ${BLACK_100} !important;
}

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: ${WHITE_COLOR};
  margin: 0rem -0.9rem;
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.no-border {
  border: none !important
}

.left {
  float: left;
}

.right {
  float: right;
}

.ReactTable.TableNominations .rt-th,
.ReactTable.TableNominations .rt-td {
  white-space: normal;
}

.required-note:after {
  content: ' *';
  color: red;
  font-weight: bold;
}

.modal-body {
  overflow: auto;
}

`;

export default GlobalStyles;
