import { createContext } from 'react';
import useMembers from '../../hooks/useMembers';

export const MembersContext = createContext({});

export const MembersProvider = ({ children }) => {
  const { setMembersContext, membersContext, setMembersDraftContext, membersDraftContext } = useMembers();
  return (
    <MembersContext.Provider value={{ setMembersContext, membersContext, setMembersDraftContext, membersDraftContext }}>
      {children}
    </MembersContext.Provider>
  );
};
