import { FEATURE_ADDITIONAL_DOCUMENTS } from 'utils/features';

const spec = {
  // <SectionTitle text="1. Informasi Pribadi" />

  product_interest: {
    name: 'product_interest',
    label: 'Produk eFishery yang Diminati',
    required: true,
  },
  kabayan_type_member: {
    name: 'kabayan_type',
    label: 'Tipe Kabayan',
    required: true,
  },
  name: {
    name: 'name',
    label: 'Nama Lengkap',
    required: true,
  },
  nick_name: {
    name: 'nick_name',
    label: 'Nama Panggilan',
    required: true,
  },
  is_cultivator: {
    name: 'is_cultivator',
    label: 'Apakah Seorang Pembudidaya?',
    required: true,
    options: ['Ya', 'Tidak'],
  },
  business_model: {
    name: 'business_model',
    label: 'Model Bisnis Budidaya',
    required: true,
  },
  cultivation_role: {
    name: 'cultivation_role',
    label: 'Peran dalam Bisnis Budidaya',
    required: true,
  },
  occupation: {
    name: 'occupation',
    label: 'Jenis Pekerjaan',
    required: true,
  },
  religion_id: {
    name: 'religion_id',
    label: 'Agama',
    required: true,
  },
  religion_name: {
    name: 'religion_name',
    label: 'Agama',
  },
  last_education_id: {
    name: 'last_education_id',
    label: 'Pendidikan Terakhir',
  },
  last_education_name: {
    name: 'last_education_name',
    label: 'Pendidikan Terakhir',
  },
  occupation_other: {
    name: 'occupation_other',
    label: null,
    required: true,
  },
  phone1: {
    name: 'phone1',
    label: 'No. Handphone 1',
    format: 'phone',
    required: true,
  },
  link_video: {
    name: 'link_video',
    format: 'link_video',
    label: 'Link Video Pernyataan Update Nomor HP',
    required: true,
  },
  link_video_2: {
    name: 'link_video_2',
    format: 'link_video',
    label: 'Link Video Pernyataan Update Nomor HP',
    required: true,
  },
  link_video_3: {
    name: 'link_video_3',
    format: 'link_video',
    label: 'Link Video Pernyataan Update Nomor HP',
    required: true,
  },
  new_phone_number: {
    name: 'new_phone_number',
    label: 'No. Handphone 1 yang baru',
    format: 'phone',
    required: true,
  },
  new_phone_number_2: {
    name: 'new_phone_number_2',
    label: 'No. Handphone 2 yang baru',
    format: 'phone',
    required: true,
  },
  new_phone_number_3: {
    name: 'new_phone_number_3',
    label: 'No. Handphone 3 yang baru',
    format: 'phone',
    required: true,
  },
  is_phone_whatsapp_registered: {
    name: 'is_phone_whatsapp_registered',
    label: 'No. Handphone 1 Terdaftar di Whatsapp',
    options: ['Ya', 'Tidak'],
    required: true,
  },
  phone2: {
    name: 'phone2',
    label: 'No. Handphone 2',
    format: 'phone',
  },
  is_phone2_whatsapp_registered: {
    name: 'is_phone2_whatsapp_registered',
    label: 'No. Handphone 2 Terdaftar di Whatsapp',
    options: ['Ya', 'Tidak'],
  },
  phone3: {
    name: 'phone3',
    label: 'No. Handphone 3',
    format: 'phone',
  },
  is_phone3_whatsapp_registered: {
    name: 'is_phone3_whatsapp_registered',
    label: 'No. Handphone 3 Terdaftar di Whatsapp',
    options: ['Ya', 'Tidak'],
  },
  email: {
    name: 'email',
    label: 'Alamat Email',
    format: 'email',
  },
  postal_address: {
    name: 'postal_address',
    label: 'Alamat Rumah/Domisili',
    required: true,
  },
  postal_province_name: {
    name: 'postal_province_name',
    label: 'Provinsi',
  },
  postal_province_id: {
    name: 'postal_province_id',
    label: 'Provinsi',
    required: true,
  },
  postal_city_name: {
    name: 'postal_city_name',
    label: 'Kota/Kabupaten',
  },
  postal_city_id: {
    name: 'postal_city_id',
    label: 'Kota/Kabupaten',
    required: true,
  },
  postal_district_name: {
    name: 'postal_district_name',
    label: 'Kecamatan',
  },
  postal_district_id: {
    name: 'postal_district_id',
    label: 'Kecamatan',
    required: true,
  },
  postal_subdistrict_name: {
    name: 'postal_subdistrict_name',
    label: 'Kelurahan/Desa',
  },
  postal_subdistrict_id: {
    name: 'postal_subdistrict_id',
    label: 'Kelurahan/Desa',
    required: true,
  },
  postal_number: {
    name: 'postal_number',
    format: 'postal_number',
    label: 'Kode Pos',
    required: true,
  },
  latitude: {
    name: 'latitude',
    label: 'Koordinat Alamat Rumah/Domisili Latitude',
    format: 'float',
    required: true,
  },
  longitude: {
    name: 'longitude',
    label: 'Koordinat Alamat Rumah/Domisili Longitude',
    format: 'float',
    required: true,
  },
  is_address_same_with_ktp: {
    name: 'is_address_same_with_ktp',
    label: 'Apakah alamat domisili saat ini sama dengan alamat KTP?',
    required: true,
    options: ['Ya', 'Tidak'],
  },
  ktp_address: {
    name: 'ktp_address',
    label: 'Alamat Sesuai KTP',
    required: true,
  },
  ktp_province: {
    name: 'ktp_province',
    label: 'Provinsi',
  },
  ktp_province_id: {
    name: 'ktp_province_id',
    label: 'Provinsi Sesuai KTP',
    required: true,
  },
  ktp_city: {
    name: 'ktp_city',
    label: 'Kota/Kabupaten Sesuai KTP',
  },
  ktp_city_id: {
    name: 'ktp_city_id',
    label: 'Kota/Kabupaten Sesuai KTP',
    required: true,
  },
  ktp_district: {
    name: 'ktp_district',
    label: 'Kecamatan Sesuai KTP',
  },
  ktp_district_id: {
    name: 'ktp_district_id',
    label: 'Kecamatan Sesuai KTP',
    required: true,
  },
  ktp_subdistrict: {
    name: 'ktp_subdistrict',
    label: 'Kelurahan/Desa Sesuai KTP',
  },
  ktp_subdistrict_id: {
    name: 'ktp_subdistrict_id',
    label: 'Kelurahan/Desa Sesuai KTP',
    required: true,
  },
  ktp_postal_number: {
    name: 'ktp_postal_number',
    format: 'postal_number',
    label: 'Kode Pos Sesuai KTP',
    required: true,
  },
  birth_place: {
    name: 'birth_place',
    label: 'Tempat Lahir',
  },
  birth_place_id: {
    name: 'birth_place_id',
    label: 'Tempat Lahir',
    required: true,
  },
  birth_date: {
    name: 'birth_date',
    label: 'Tanggal Lahir',
    showYearDropdown: true,
    required: true,
  },
  gender: {
    name: 'gender',
    label: 'Jenis Kelamin',
    options: ['Laki-laki', 'Perempuan'],
    required: true,
  },
  marriage_status: {
    name: 'marriage_status',
    label: 'Status Perkawinan',
    options: ['Kawin', 'Belum Kawin', 'Duda/Janda'],
    required: true,
  },
  ktp_number: {
    name: 'ktp_number',
    label: 'Nomor KTP',
    format: 'ktp',
    required: true,
  },
  family_card_number: {
    name: 'family_card_number',
    label: 'No Kartu Keluarga',
    required: true,
  },
  is_having_npwp: {
    name: 'is_having_npwp',
    label: 'Memiliki NPWP?',
    options: ['Ya', 'Tidak'],
  },
  npwp_number: {
    name: 'npwp_number',
    label: 'No. NPWP',
    required: true,
  },
  bank_name: {
    name: 'bank_name',
    label: 'Nama Bank yang Digunakan',
  },
  bank_account_number: {
    name: 'bank_account_number',
    label: 'Nomor Rekening Bank',
  },

  // <SectionTitle text="2. Informasi Budidaya" />

  // <SectionTitle text="2.1. Informasi Lokasi Budidaya" secondary />
  location_address: {
    name: 'location_address',
    label: 'Alamat Kolam Budidaya',
    required: true,
  },
  location_province_name: {
    name: 'location_province_name',
    label: 'Provinsi',
  },
  location_province_id: {
    name: 'location_province_id',
    label: 'Provinsi',
    required: true,
  },
  location_city_name: {
    name: 'location_city_name',
    label: 'Kota/Kabupaten',
  },
  location_city_id: {
    name: 'location_city_id',
    label: 'Kota/Kabupaten',
    required: true,
  },
  location_district_name: {
    name: 'location_district_name',
    label: 'Kecamatan',
  },
  location_district_id: {
    name: 'location_district_id',
    label: 'Kecamatan',
    required: true,
  },
  location_subdistrict_name: {
    name: 'location_subdistrict_name',
    label: 'Kelurahan/Desa',
  },
  location_subdistrict_id: {
    name: 'location_subdistrict_id',
    label: 'Kelurahan/Desa',
    required: true,
  },
  location_postal_number: {
    name: 'location_postal_number',
    format: 'postal_number',
    label: 'Kode Pos',
    required: true,
  },
  location_latitude: {
    name: 'location_latitude',
    label: 'Koordinat Latitude Alamat Kolam Budidaya',
    format: 'float',
  },
  location_longitude: {
    name: 'location_longitude',
    label: 'Koordinat Longitude Alamat Kolam Budidaya',
    format: 'float',
  },
  location_electricity_resource: {
    name: 'location_electricity_resource',
    label: 'Sumber Listrik',
    required: true,
  },
  location_electricity_resource_other: {
    name: 'location_electricity_resource_other',
    label: null,
    required: true,
  },
  location_water_resource: {
    name: 'location_water_resource',
    label: 'Sumber Air',
    required: true,
  },
  location_water_resource_other: {
    name: 'location_water_resource_other',
    label: null,
    required: true,
  },
  location_water_sources_distance_in_m: {
    name: 'location_water_sources_distance_in_m',
    label: 'Jarak ke Sumber Air (meter)',
    required: true,
  },
  // <SectionTitle text="2.2. Informasi Komoditas Budidaya" secondary />
  commodity_name: {
    name: 'commodity_name',
    label: 'Nama Komoditas Utama',
  },
  commodity_id: {
    name: 'commodity_id',
    label: 'Nama Komoditas Utama',
    required: true,
  },
  commodity_is_pond_multiple_commodity: {
    name: 'commodity_is_pond_multiple_commodity',
    label: 'Apakah melakukan Diversifikasi Budidaya?',
    options: ['Ya', 'Tidak'],
    required: true,
  },
  commodity_alternate_commodity_name_1: {
    name: 'commodity_alternate_commodity_name_1',
    label: 'Nama Komoditas Lainnya 1',
  },
  commodity_alternate_commodity_id_1: {
    name: 'commodity_alternate_commodity_id_1',
    label: 'Nama Komoditas Lainnya 1',
    required: true,
  },
  commodity_alternate_commodity_name_2: {
    name: 'commodity_alternate_commodity_name_2',
    label: 'Nama Komoditas Lainnya 2',
  },
  commodity_alternate_commodity_id_2: {
    name: 'commodity_alternate_commodity_id_2',
    label: 'Nama Komoditas Lainnya 2',
  },
  commodity_alternate_commodity_name_3: {
    name: 'commodity_alternate_commodity_name_3',
    label: 'Nama Komoditas Lainnya 3',
  },
  commodity_alternate_commodity_id_3: {
    name: 'commodity_alternate_commodity_id_3',
    label: 'Nama Komoditas Lainnya 3',
  },
  commodity_alternate_commodity_name_4: {
    name: 'commodity_alternate_commodity_name_4',
    label: 'Nama Komoditas Lainnya 4',
  },
  commodity_alternate_commodity_id_4: {
    name: 'commodity_alternate_commodity_id_4',
    label: 'Nama Komoditas Lainnya 4',
  },
  commodity_focus_phase_commodity: {
    name: 'commodity_focus_phase_commodity',
    label: 'Fokus Fase Budidaya (Dapat memilih lebih dari satu fase)',
    required: true,
  },
  commodity_pond_count: {
    name: 'commodity_pond_count',
    label: 'Jumlah Kolam',
    format: 'int',
    required: true,
  },
  pond_total_area_m2: {
    name: 'pond_total_area_m2',
    label: 'Total luas keseluruhan kolam',
    format: 'int',
    required: true,
  },
  commodity_active_pond_count: {
    name: 'commodity_active_pond_count',
    label: 'Jumlah Kolam Aktif',
    format: 'int',
    required: true,
  },
  commodity_pond_size_m2: {
    name: 'commodity_pond_size_m2',
    label: 'Rata-rata Ukuran Kolam (m²)',
    format: 'float',
    required: true,
  },
  commodity_pond_min_length_m: {
    name: 'commodity_pond_min_length_m',
    label: 'Panjang Kolam Terkecil (meter)',
    format: 'float',
    required: true,
  },
  commodity_pond_min_width_m: {
    name: 'commodity_pond_min_width_m',
    label: 'Lebar Kolam Terkecil (meter)',
    format: 'float',
    required: true,
  },
  commodity_pond_max_length_m: {
    name: 'commodity_pond_max_length_m',
    label: 'Panjang Kolam Terbesar (meter)',
    format: 'float',
    required: true,
  },
  commodity_pond_max_width_m: {
    name: 'commodity_pond_max_width_m',
    label: 'Lebar Kolam Terbesar (meter)',
    format: 'float',
    required: true,
  },
  commodity_complied_with_feeder_requirement_pond_count: {
    name: 'commodity_complied_with_feeder_requirement_pond_count',
    label: 'Jumlah Kolam yang Cocok dengan Feeder (Ukuran minimum kolam yang cocok : 5 x 7 m)',
    format: 'int',
    required: true,
  },
  commodity_pond_capacity: {
    name: 'commodity_pond_capacity',
    label: 'Kapasitas per Kolam (dalam satuan ekor)',
    format: 'int',
    required: true,
  },
  commodity_next_stocking_start_date: {
    name: 'commodity_next_stocking_start_date',
    label: 'Perkiraan tebar benih baru - Awal Priode',
    required: true,
  },
  commodity_next_stocking_end_date: {
    name: 'commodity_next_stocking_end_date',
    label: 'Perkiraan tebar benih baru - Akhir Priode',
    required: true,
  },
  commodity_next_stocking_pond_count: {
    name: 'commodity_next_stocking_pond_count',
    label: 'Jumlah Kolam yg Akan Ditebar Benih Baru?',
    required: true,
  },
  commodity_pond_cultivation_cycle_time_unit: {
    name: 'commodity_pond_cultivation_cycle_time_unit',
    label: 'Satuan Lama Masa Siklus Budidaya',
    options: ['Hari', 'Minggu', 'Bulan'],
    required: true,
  },
  commodity_pond_cultivation_cycle_time: {
    name: 'commodity_pond_cultivation_cycle_time',
    label: 'Lama Masa Siklus Budidaya',
    format: 'float',
    required: true,
  },
  commodity_pond_owning_status: {
    name: 'commodity_pond_owning_status',
    label: 'Status Kepemilikan Kolam',
    required: true,
  },
  commodity_pond_owning_status_other: {
    name: 'commodity_pond_owning_status_other',
    label: null,
    required: true,
  },

  // <SectionTitle text="2.3. Informasi Penggunaan Pakan per Kolam" secondary />
  feeds_start_doc: {
    name: 'feeds_start_doc',
    label: 'DOC Awal',
    format: 'int',
  },
  feeds_end_doc: {
    name: 'feeds_end_doc',
    label: 'DOC Akhir',
    format: 'int',
  },
  feeds_code: {
    name: 'feeds_code',
    label: 'Kode Pakan',
  },
  feeds_code_new: {
    name: 'feeds_code_new',
    label: 'Kode Pakan Yang Baru',
  },
  feed_code_submission: {
    name: 'feed_code_submission',
    label: 'Kode Pakan Yang Baru',
  },
  feeds_type: {
    name: 'feeds_type',
    label: 'Jenis Pakan',
  },
  feeds_brand: {
    name: 'feeds_brand',
    label: 'Merk Pakan',
  },
  feeds_brand_new: {
    name: 'feeds_brand_new',
    label: 'Merk Pakan Yang Baru',
  },
  feed_brand_submission: {
    name: 'feed_brand_submission',
    label: 'Merk Pakan Yang Baru',
  },
  feeds_size: {
    name: 'feeds_size',
    label: 'Ukuran Pakan (mm)',
    format: 'float',
  },
  feeds_usage_amount_doc: {
    name: 'feeds_usage_amount_doc',
    label: 'Jumlah Penggunaan Pakan (sak)',
    format: 'float',
  },
  feeds_weight_per_sack: {
    name: 'feeds_weight_per_sack',
    label: 'Berat Pakan (kg) / 1 Sak',
    format: 'float',
  },
  feeds_price_per_sack: {
    name: 'feeds_price_per_sack',
    label: 'Harga Pakan (Rp) / 1 Sak',
    format: 'idr',
  },
  feeds_buy_method: {
    name: 'feeds_buy_method',
    label: 'Metode Pembelian Pakan',
    options: ['Tunai', 'Cicilan', 'Tunggu Akhir Panen'],
  },
  feeds_buy_method_other: {
    name: 'feeds_buy_method_other',
    label: null,
  },

  // <SectionTitle text="2.4. Informasi Panen" secondary />
  commodity_harvest_timebound_unit: {
    name: 'commodity_harvest_timebound_unit',
    label: 'Satuan Melakukan Panen Setiap',
    options: ['Hari', 'Minggu', 'Bulan'],
    required: true,
  },
  commodity_harvest_timebound: {
    name: 'commodity_harvest_timebound',
    label: 'Anda Melakukan Panen Setiap',
    format: 'float',
    required: true,
  },
  commodity_harvest_amount_avg_unit: {
    name: 'commodity_harvest_amount_avg_unit',
    label: 'Satuan Rata-rata Tonase Panen per Kolam',
    required: true,
  },
  commodity_harvest_amount_avg: {
    name: 'commodity_harvest_amount_avg',
    label: 'Rata-rata Tonase Panen per Kolam',
    format: 'float',
    required: true,
  },
  commodity_harvest_amount_target_unit: {
    name: 'commodity_harvest_amount_target_unit',
    label: 'Satuan Target Tonase Panen per Kolam',
    required: true,
  },
  commodity_harvest_amount_target: {
    name: 'commodity_harvest_amount_target',
    label: 'Target Tonase Panen per Kolam',
    format: 'float',
    required: true,
  },
  commodity_harvest_selling_price_per_kg_avg: {
    name: 'commodity_harvest_selling_price_per_kg_avg',
    label: 'Harga Jual Komoditas per KG (Rata-rata)',
    format: 'idr',
    required: true,
  },
  commodity_harvest_selling_price_per_kg_min: {
    name: 'commodity_harvest_selling_price_per_kg_min',
    label: 'Harga Jual Komoditas per KG (Terendah)',
    format: 'idr',
    required: true,
  },
  commodity_harvest_selling_price_per_kg_max: {
    name: 'commodity_harvest_selling_price_per_kg_max',
    label: 'Harga Jual Komoditas per KG (Tertinggi)',
    format: 'idr',
    required: true,
  },
  commodity_harvest_buyer_name: {
    name: 'commodity_harvest_buyer_name',
    label: 'Nama Pembeli Ikan',
    required: true,
  },
  commodity_harvest_buyer_city: {
    name: 'commodity_harvest_buyer_city',
    label: 'Area Pembeli Ikan',
    required: true,
  },
  commodity_harvest_buyer_city_id: {
    name: 'commodity_harvest_buyer_city_id',
    label: 'Area Pembeli Ikan',
    required: true,
  },
  commodity_harvest_buyer_phone: {
    name: 'commodity_harvest_buyer_phone',
    label: 'No. Handphone Pembeli Ikan',
    format: 'phone',
    required: true,
  },
  commodity_harvest_buyer_type_id: {
    name: 'commodity_harvest_buyer_type_id',
    label: 'Tipe Pembeli Ikan',
    required: true,
  },
  commodity_harvest_buyer_type: {
    name: 'commodity_harvest_buyer_type',
    label: 'Tipe Pembeli Ikan',
    required: true,
  },
  commodity_harvest_fcr_value: {
    name: 'commodity_harvest_fcr_value',
    label: 'Nilai FCR',
    format: 'float',
  },

  // <SectionTitle text="3. Informasi Akuisisi" />
  officer_email: {
    name: 'officer_email',
    label: 'Nama Petugas Registrasi',
  },
  officer_name: {
    name: 'officer_name',
    label: 'Nama Petugas Registrasi',
  },
  point_name: {
    name: 'point_name',
    label: 'Nama Point',
    required: true,
  },

  // <SectionTitle text="4. Informasi KYC" />

  // <SectionTitle text="4.1. Informasi KYC Personal" secondary />
  maiden_name: {
    name: 'maiden_name',
    label: 'Nama Ibu Kandung',
  },

  // <SectionTitle text="4.1.a. Informasi Pasangan" secondary />
  spouse_name: {
    name: 'spouse_name',
    label: 'Nama Pasangan',
  },
  spouse_phone: {
    name: 'spouse_phone',
    label: 'No. Handphone Pasangan',
    format: 'phone',
  },
  spouse_address: {
    name: 'spouse_address',
    label: 'Alamat Pasangan',
  },
  spouse_province_name: {
    name: 'spouse_province_name',
    label: 'Provinsi',
  },
  spouse_province_id: {
    name: 'spouse_province_id',
    label: 'Provinsi',
  },
  spouse_city_name: {
    name: 'spouse_city_name',
    label: 'Kota/Kabupaten',
  },
  spouse_city_id: {
    name: 'spouse_city_id',
    label: 'Kota/Kabupaten',
  },
  spouse_district_name: {
    name: 'spouse_district_name',
    label: 'Kecamatan',
  },
  spouse_district_id: {
    name: 'spouse_district_id',
    label: 'Kecamatan',
  },
  spouse_subdistrict_name: {
    name: 'spouse_subdistrict_name',
    label: 'Kelurahan/Desa',
  },
  spouse_subdistrict_id: {
    name: 'spouse_subdistrict_id',
    label: 'Kelurahan/Desa',
  },
  spouse_postal_number: {
    name: 'spouse_postal_number',
    format: 'postal_number',
    label: 'Kode Pos Pasangan',
  },
  spouse_birth_place: {
    name: 'spouse_birth_place',
    label: 'Tempat Lahir Pasangan',
  },
  spouse_birth_place_id: {
    name: 'spouse_birth_place_id',
    label: 'Tempat Lahir Pasangan',
  },
  spouse_birth_date: {
    name: 'spouse_birth_date',
    label: 'Tanggal Lahir Pasangan',
  },
  spouse_ktp_number: {
    name: 'spouse_ktp_number',
    label: 'No. KTP Pasangan',
    format: 'ktp',
    required: true,
  },

  // <SectionTitle text="4.1.b. Informasi Kontak Darurat" secondary />
  emergency_contact_name: {
    name: 'emergency_contact_name',
    label: 'Nama Kontak saat Darurat',
  },
  emergency_contact_phone: {
    name: 'emergency_contact_phone',
    label: 'No. Handphone Kontak saat Darurat',
    format: 'phone',
  },
  emergency_contact_address: {
    name: 'emergency_contact_address',
    label: 'Alamat Rumah / Domisili Kontak Darurat',
  },
  emergency_contact_relation: {
    name: 'emergency_contact_relation',
    label: 'Status Relasi',
  },
  emergency_contact_relation_other: {
    name: 'emergency_contact_relation_other',
    label: null,
  },

  // <SectionTitle text="4.1.c. Informasi Aset" secondary />
  asset_motorcycle_count: {
    name: 'asset_motorcycle_count',
    label: 'Jumlah Aset Kendaraan Roda Dua',
    format: 'int',
  },
  asset_car_count: {
    name: 'asset_car_count',
    label: 'Jumlah Aset Kendaraan Roda Empat',
    format: 'int',
  },
  residential_status: {
    name: 'residential_status',
    label: 'Status Kepemilikan Rumah',
  },
  residential_occupy_period: {
    name: 'residential_occupy_period',
    label: 'Lama Menempati Rumah (Tahun)',
    format: 'float',
  },

  is_having_debt: {
    name: 'is_having_debt',
    label: 'Apakah Anda Pernah Memiliki Pinjaman?',
    options: ['Ya', 'Tidak'],
  },
  debt_object: {
    name: 'debt_object',
    label: 'Lembaga Pemberi Pinjaman',
    options: ['Bank', 'Koperasi', 'Perorangan', 'others'],
    required: true,
  },
  debt_object_other: {
    name: 'debt_object_other',
    label: null,
    required: true,
  },
  debt_history_institution_name: {
    name: 'debt_history_institution_name',
    label: 'Nama Lembaga Pemberi Pinjaman',
    required: true,
  },
  debt_amount: {
    name: 'debt_amount',
    label: 'Jumlah Pinjaman',
    format: 'idr',
    required: true,
  },
  is_having_bad_debt: {
    name: 'is_having_bad_debt',
    label: 'Pernah Memiliki Kredit Macet?',
    options: ['Ya', 'Tidak'],
  },

  // <SectionTitle text="4.2. Informasi KYC Budidaya" secondary />
  active_pond_submission_count: {
    name: 'active_pond_submission_count',
    label: 'Jumlah Kolam Aktif yang Diajukan Pembiayaan',
    format: 'int',
  },
  location_experience_duration: {
    name: 'location_experience_duration',
    label: 'Lama Pengalaman Budidaya (Tahun)',
    format: 'float',
  },
  location_production_cost: {
    name: 'location_production_cost',
    label: 'Biaya Produksi Budidaya / Siklus',
    format: 'idr',
  },
  location_pond_operating_cost: {
    name: 'location_pond_operating_cost',
    label: 'Biaya Operasional Budidaya / Siklus',
    format: 'idr',
  },
  location_profit_per_cycle: {
    name: 'location_profit_per_cycle',
    label: 'Keuntungan Budidaya / Siklus',
    format: 'idr',
  },
  location_is_having_employee: {
    name: 'location_is_having_employee',
    label: 'Apakah Anda Memiliki Karyawan?',
    options: ['Ya', 'Tidak'],
  },
  location_employee_numbers: {
    name: 'location_employee_numbers',
    label: 'Jumlah Karyawan',
    format: 'int',
  },
  location_employee_salary_per_month: {
    name: 'location_employee_salary_per_month',
    label: 'Gaji Karyawan per Bulan',
    format: 'idr',
  },
  location_pond_count_managed_by_employee: {
    name: 'location_pond_count_managed_by_employee',
    label: 'Jumlah Kolam yang Dikelola per Karyawan',
    format: 'int',
  },

  // <SectionTitle text="5. Tanda Tangan" />
  registration_date: {
    name: 'registration_date',
    label: 'Tanggal Tanda Tangan Dokumen Registrasi Member',
    required: true,
  },

  // <SectionTitle text="6. Upload Dokumen" />
  ktp_image: {
    name: 'ktp_image',
    label: 'Upload Foto KTP',
    accept: '.pdf,image/*',
    required: true,
  },
  domicile_statement_file: {
    name: 'domicile_statement_file',
    label: 'Upload Surat Pernyataan Domisili',
    accept: '.pdf,image/*',
    required: true,
  },
  npwp_image: {
    name: 'npwp_image',
    label: 'Upload Foto NPWP',
    accept: '.pdf,image/*',
    required: true,
  },
  npwp_statement_image: {
    name: 'npwp_statement_image',
    label: 'Upload Foto Pernyataan Tidak Punya NPWP',
    accept: '.pdf,image/*',
    required: true,
  },
  family_card_image: {
    name: 'family_card_image',
    label: 'Upload Foto Kartu Keluarga',
    accept: '.pdf,image/*',
    required: true,
  },
  kyc_image: {
    name: 'kyc_image',
    label: 'Upload Swafoto Member dengan KTP',
    accept: '.pdf,image/*',
  },
  registration_image: {
    name: 'registration_image',
    label: 'Upload Dokumen Registrasi Member',
    accept: '.pdf,image/*',
    required: true,
  },
  commodity_pond_image: {
    name: 'commodity_pond_image',
    label: 'Upload Foto Kolam (1)',
    accept: '.pdf,image/*',
  },
  commodity_pond_image_2: {
    name: 'commodity_pond_image_2',
    label: 'Upload Foto Kolam (2)',
    accept: '.pdf,image/*',
  },
  commodity_pond_image_3: {
    name: 'commodity_pond_image_3',
    label: 'Upload Foto Kolam (3)',
    accept: '.pdf,image/*',
  },
  house_image_1: {
    name: 'house_image',
    label: 'Upload Foto Rumah Tinggal dengan Geotag (1)',
    accept: '.pdf,image/*',
    required: true,
  },
  pond_ownership_document_file: {
    name: 'pond_ownership_document_file',
    label: 'Upload Dokumen Kepemilikan Kolam',
    accept: '.pdf,image/*',
    required: true,
  },
  house_ownership_document_file: {
    name: 'house_ownership_document_file',
    label: 'Upload Dokumen Kepemilikan Rumah',
    accept: '.pdf,image/*',
    required: false,
  },
  feed_purchase_receipt_last_cycle_file: {
    name: 'feed_purchase_receipt_last_cycle_file',
    label: 'Upload Nota Pembelian Pakan 1 siklus terakhir',
    accept: '.pdf,image/*',
    required: false,
  },
  fish_sales_receipt_last_cycle_file: {
    name: 'fish_sales_receipt_last_cycle_file',
    label: 'Upload Nota Penjualan Ikan 1 siklus terakhir',
    accept: '.pdf,image/*',
    required: false,
  },
  payslip_last_month: {
    name: 'payslip_last_month',
    label: 'Upload Slip Gaji 1 bulan Terakhir apabila memiliki Pekerjaan Tetap',
    accept: '.pdf,image/*',
    required: false,
  },
  spouse_ktp_image: {
    name: 'spouse_ktp_image',
    label: 'Upload Foto KTP Pasangan',
    accept: '.pdf,image/*',
    required: false,
  },
  house_image_2: {
    name: 'house_image_2',
    label: 'Upload Foto Rumah Tinggal dengan Geotag (2)',
    accept: '.pdf,image/*',
    required: true,
  },
  account_mutation_url: {
    name: 'account_mutation_url',
    label: 'Upload Mutasi Rekening Koran',
    accept: '.pdf,image/*',
    required: true,
  },
  assets_photo_url: {
    name: 'assets_photo_url',
    label: 'Upload Foto Aset Keseluruhan',
    accept: '.pdf,image/*',
    required: true,
  },
  ...(FEATURE_ADDITIONAL_DOCUMENTS
    ? {
        other_document_1: {
          name: 'other_document_1',
          label: 'Dokumen Lainnya 1',
          accept: '.pdf,image/*',
          required: false,
        },
        other_document_2: {
          name: 'other_document_2',
          label: 'Dokumen Lainnya 2',
          accept: '.pdf,image/*',
          required: false,
        },
        other_document_3: {
          name: 'other_document_3',
          label: 'Dokumen Lainnya 3',
          accept: '.pdf,image/*',
          required: false,
        },
      }
    : {}),
  other_information: {
    name: 'other_information',
    label: 'Informasi Lainnya',
  },

  // /* tak dipakai lagi */
  // //
  // is_farmer_knew_feed_code: {
  //   name: 'is_farmer_knew_feed_code',
  //   label: 'Apakah Pembudidaya Mengetahui Kode Pakan?',
  //   options: ['Ya', 'Tidak'],
  // },
  // feed_usage_amount: {
  //   name: 'feed_usage_amount',
  //   label: 'Jumlah Penggunaan Pakan per kolam per siklus',
  //   format: 'int',
  // },
  // feed_usage_amount_unit: {
  //   name: 'feed_usage_amount_unit',
  //   label: 'Unit Jumlah Penggunaan Pakan',
  //   options: ['Kilogram', 'Kuintal', 'Ton', 'Sak'],
  // },
  // feed_usage_timebound: {
  //   name: 'feed_usage_timebound',
  //   label: 'Periode Penggunaan Pakan',
  //   format: 'int',
  // },
  // feed_usage_timebound_unit: {
  //   name: 'feed_usage_timebound_unit',
  //   label: 'Unit Periode Penggunaan Pakan',
  //   options: ['Hari', 'Minggu', 'Bulan', 'Tahun'],
  // },
  // //
  // is_agenpakan_recommends: {
  //   name: 'is_agenpakan_recommends',
  //   label: 'Apakah Agen Pakan Merekomendasikan Jenis Pakan?',
  //   options: ['Ya', 'Tidak'],
  // },
  // agenpakan_recommends_name: {
  //   name: 'agenpakan_recommends_name',
  //   label: 'Nama Agen Pakan',
  // },
  // agenpakan_recommends_phone: {
  //   name: 'agenpakan_recommends_phone',
  //   label: 'No. Handphone Agen Pakan',
  //   format: 'phone',
  // },
  // //
  // subchannel: {
  //   name: 'subchannel',
  //   label: 'Nama SubChannel',
  // },
  // subchannel_id: {
  //   name: 'subchannel_id',
  //   label: 'Nama SubChannel',
  // },
  // is_referral_is_member: {
  //   name: 'is_referral_is_member',
  //   label: 'Apakah Referral Merupakan Member eFishery?',
  //   options: ['Ya', 'Tidak'],
  // },
  // referral_member_id: {
  //   name: 'referral_member_id',
  //   label: 'Nama Referral',
  // },
};

const createFeedFields = () => {
  for (let number = 2; number <= 3; number += 1) {
    for (const field of [
      'feeds_start_doc',
      'feeds_end_doc',
      'feeds_code',
      'feeds_code_new',
      'feed_code_submission',
      'feeds_type',
      'feeds_brand',
      'feeds_brand_new',
      'feed_brand_submission',
      'feeds_size',
      'feeds_usage_amount_doc',
      'feeds_weight_per_sack',
      'feeds_price_per_sack',
      'feeds_buy_method',
      'feeds_buy_method_other',
    ]) {
      const name = `${field}_${number}`;
      spec[name] = {
        ...spec[field],
        name,
      };
    }
  }
};

createFeedFields();
export default spec;
