import { PopupProvider, PopupContext } from './PopupContext';
import { FlagrProvider } from 'utils/context/FlagrContext';

import { AccessProvider } from 'utils/context/AccessContext';

const SuperProviderGlobal = ({ children }) => {
  return (
    <FlagrProvider>
      <AccessProvider>
        <PopupProvider>{children}</PopupProvider>
      </AccessProvider>
    </FlagrProvider>
  );
};

export { PopupContext };
export default SuperProviderGlobal;
