export const either = (valueOne, valueTwo) => {
  /* coalescing https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Nullish_coalescing_operator
   * implement sendiri in case versi JS yang digunakan belum support coalescing operator
   */
  const hasValueOne = valueOne !== null && valueOne !== undefined && valueOne !== ''; // in our case, empty string should be coalesced
  return hasValueOne ? valueOne : valueTwo;
};

export const toBoolean = value => {
  if (typeof value === 'boolean') return value;
  if (value === 'Ya') return true;
  if (value === 'Tidak') return false;
  if (value === '1') return true;
  if (value === '0') return false;
  if (value === 'true') return true;
  if (value === 'false') return false;
  return undefined;
};

export const toYaTidak = value => {
  if (value === true) return 'Ya';
  if (value === false) return 'Tidak';
  return undefined;
};

export const toYaTidakInt = value => {
  if (value === true) return '1';
  if (value === false) return '0';
  return undefined;
};

export const toGender = value => {
  if (value === 'M') return 'Laki-laki';
  if (value === 'F') return 'Perempuan';
  if (value === 'Laki-laki') return 'Laki-laki';
  if (value === 'Perempuan') return 'Perempuan';
  return undefined;
};

export const toIsKey = value => {
  if (value === 'Key') return 'key';
  if (value === 'Not Key') return 'not_key';
  return undefined;
};

export const toKeyStatus = value => {
  if (value === 'key') return 'Key';
  if (value === 'not_key') return 'Not Key';
  if (value === 'Key') return 'Key';
  if (value === 'Not Key') return 'Not Key';
  if (value === 'Not_Key') return 'Not Key';
  return undefined;
};

export const toProductInterest = value => {
  if (Array.isArray(value)) return value.join(',');
  return undefined;
};

export const toNumber = value => {
  const number = parseFloat(value);
  return Number.isNaN(number) ? undefined : number;
};

export const merge = (objOne, objTwo) => {
  /* the difference with Object.assign is in this method undefined and null will not overwrite */
  const result = Object.assign({}, objOne);

  for (const [keyTwo, valueTwo] of Object.entries(objTwo)) {
    if (valueTwo === null || valueTwo === undefined) continue;
    result[keyTwo] = valueTwo;
  }

  return result;
};

export const isNotEmptyInt = value => {
  if (value === '' || value === null || value === undefined) return false;
  return true;
};

export const capitalize = s => (s && s?.[0]?.toUpperCase() + s.slice(1)) || '';
