import packageJson from '../../package.json';
import inputs from './inputs';
import wording from './wording';
export { default as env } from './env';

const config = {
  appName: packageJson.name,
  version: packageJson.version,
  colorscore: {
    red: {
      from: 0,
      threshold: 0.5,
      color: 'rgb(255, 0, 0)',
    },
    yellow: {
      from: 0.5,
      threshold: 0.65,
      color: 'rgb(230, 230, 0)',
    },
    green: {
      from: 0.65,
      threshold: 1,
      color: 'rgb(0, 182, 0)',
    },
    valueToColor: value => {
      return value <= config.colorscore.red.threshold
        ? config.colorscore.red.color
        : value <= config.colorscore.yellow.threshold
        ? config.colorscore.yellow.color
        : config.colorscore.green.color;
    },
  },
  inputs,
  wording,
};

export default config;
