import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const NominationFunctionPages = loadable(() => import(/* webpackChunkName: "InterviewPages" */ './index'));
const nominationFunctionRoute = {
  routeProps: {
    path: PATH.NOMINATIONS,
    component: NominationFunctionPages,
  },
  isPublic: false,
  name: 'nomination',
};

export default nominationFunctionRoute;
