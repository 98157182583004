import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const LoginPages = loadable(() => import(/* webpackChunkName: "LoginPages" */ './index'));
const loginRoute = {
  routeProps: {
    exact: true,
    path: PATH.LOGIN,
    component: LoginPages,
  },
  isPublic: true,
  name: 'Login',
};

export default loginRoute;
