import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const HistoryNominatifFunctionPages = loadable(() => import(/* webpackChunkName: "InterviewPages" */ './index'));
const historyNominatifFunctionRoute = {
  routeProps: {
    path: PATH.HISTORY_NOMINATIF,
    component: HistoryNominatifFunctionPages,
  },
  isPublic: false,
  name: 'HistoryNominatifFunction',
};

export default historyNominatifFunctionRoute;
