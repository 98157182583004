import { capitalize } from 'utils/libs/transformers/commons';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Typography } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import { withStyles } from '@material-ui/core/styles';
import tw, { styled } from 'twin.macro';

const bodyMessageErrorForm = ({ errorMessage, isUploadBody, fieldsError, isValidation, errId, errString }) => {
  return (
    <>
      {isUploadBody ? (
        <>
          <ImageError>Gagal Upload File</ImageError>
          <Typography> {capitalize(errorMessage)}</Typography>
          <FlexWrap>
            <Wrap>
              <Title>Error ID</Title>
              <Dots>:</Dots>
              <Caption>{errId}</Caption>
            </Wrap>
          </FlexWrap>
          <Wrap>
            <Title>Note</Title>
            <Dots>:</Dots>
            <Caption>silahkan coba kembali</Caption>
          </Wrap>
        </>
      ) : (
        <Flex>
          <Typography> {capitalize(errorMessage)}</Typography>
          {!isValidation && (
            <FlexWrap>
              <Wrap>
                <Title>Error ID</Title>
                <Dots>:</Dots>
                <Caption>{errId}</Caption>
              </Wrap>
              <Wrap>
                <Title>Error</Title>
                <Dots>:</Dots>
                <Caption>{errString}</Caption>
              </Wrap>
            </FlexWrap>
          )}
        </Flex>
      )}

      {isValidation && (
        <Flex>
          <Accordion>
            <AccordionSummary
              expandIcon={
                <ExpandIcon
                  tw="w-6 h-6 text-black-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </ExpandIcon>
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Details : </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Flex>
                <Wrap>
                  <Title>Error ID</Title>
                  <Dots>:</Dots>
                  <Caption>{errId}</Caption>
                </Wrap>
                <Wrap>
                  <Title>Field</Title>
                  <Dots>:</Dots>
                  <Trunance>
                    {fieldsError?.length > 0 &&
                      fieldsError?.slice(0, 3).map((el, index) => {
                        return <span key={index}>{(index ? ', ' : '') + el.field}</span>;
                      })}
                  </Trunance>
                </Wrap>
                <Wrap>
                  <Title>Note</Title>
                  <Dots>:</Dots>
                  <Caption>close pop up ini dan periksa kembali form isian anda</Caption>
                </Wrap>
              </Flex>
            </AccordionDetails>
          </Accordion>
        </Flex>
      )}
    </>
  );
};

export default bodyMessageErrorForm;

const Accordion = withStyles({
  root: {
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    backgroundColor: 'transparent',
    border: 'none',
    padding: 0,
    minHeight: '20px',

    '& .MuiButtonBase-root': {
      padding: 0,
      minHeight: '20px',
      justifyContent: 'flex-start',
      backgroundColor: 'transparent',
      borderBottom: 'none',
    },
    '& .MuiAccordionSummary-content': {
      margin: '8px 0',
      flexGrow: 0,
    },
  },
  expanded: {},
})(MuiAccordion);
const AccordionDetails = withStyles(theme => ({
  root: {
    padding: 0,
  },
}))(MuiAccordionDetails);
const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    padding: 0,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const ExpandIcon = tw.svg`w-4 h-4 text-black-500`;
const Flex = tw.div`flex flex-col`;
const FlexWrap = tw.div`flex flex-col mt-4`;
const Wrap = styled.div`
  ${tw`flex flex-row`}
  & > p {
    margin-bottom: 4px;
  }
`;
const Title = styled.p`
  max-width: 80px;
  ${tw`font-bold w-full`};
`;
const Dots = tw.p`mx-2`;
const Caption = tw.p`text-black-500 flex break-all`;
const Trunance = styled.div`
  width: 50%;
  @media query(min-width: 768px) {
    width: 80%;
  }
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${tw` break-all`}
`;
const ImageError = tw.p`font-bold mb-4`;
