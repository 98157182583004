import { KabayansContext, KabayansProvider } from './KabayanContext';
import { Members1Context, Members1Provider } from './Member1Context';
import { MembersContext, MembersProvider } from './MemberContext';
import { NominationsContext, NominationsProvider } from './NominationContext';

const SuperProviderHome = ({ children }) => {
  return (
    <MembersProvider>
      <Members1Provider>
        <KabayansProvider>
          <NominationsProvider>{children}</NominationsProvider>
        </KabayansProvider>
      </Members1Provider>
    </MembersProvider>
  );
};

export { MembersContext, Members1Context, KabayansContext, NominationsContext };
export default SuperProviderHome;
