import { useState, useCallback } from 'react';

const useKabayan = () => {
  const [kabayansContext, setKabayanContext] = useState();

  const setKabayansContext = useCallback(data => {
    setKabayanContext(prevState => ({
      ...prevState,
      data,
    }));
  }, []);

  return { setKabayansContext, kabayansContext };
};

export default useKabayan;
