import React from 'react';
import { any } from 'prop-types';
import useAuthV2 from 'utils/hooks/useAuthV2';

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children, ...props }) => {
  const obj = useAuthV2();

  return (
    <AuthContext.Provider value={obj} {...props}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: any.isRequired,
};
