import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const PengajuanUpgradingFunctionPages = loadable(() => import(/* webpackChunkName: "InterviewPages" */ './index'));
const pengajuanUpgradingFunctionRoute = {
  routeProps: {
    path: PATH.PENGAJUAN_UPGRADING,
    component: PengajuanUpgradingFunctionPages,
  },
  isPublic: false,
  name: 'PengajuanUpgradingFunction',
};

export default pengajuanUpgradingFunctionRoute;
