import React, { memo, useContext } from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { PopupContext } from 'utils/context';

const Popup = memo(() => {
  const { hidePopup, properties } = useContext(PopupContext);
  return (
    <Modal
      isOpen={properties.isOpen}
      size={properties.size}
      toggle={hidePopup}
      className="shared-component Popup"
      data-testid={properties['data-testid']}
    >
      {properties.title && <ModalHeader toggle={hidePopup}>{properties.title}</ModalHeader>}
      <ModalBody>{properties.body}</ModalBody>
      {properties.footer && <ModalFooter>{properties.footer}</ModalFooter>}
    </Modal>
  );
});

export default Popup;
