import loadable from '@loadable/component';
import { PATH } from 'routes/constants';

const NominationCoopPages = loadable(() => import(/* webpackChunkName: "NominationCoopPages" */ './index'));
const nominationCoopRoute = {
  routeProps: {
    exact: true,
    path: PATH.NOMINATION_COOP,
    component: NominationCoopPages,
  },
  isPublic: false,
  name: 'nomination_coop',
};

export default nominationCoopRoute;
