import { useState, useCallback } from 'react';

const useNominations = () => {
  const [nominationsContext, setNominationContext] = useState();
  const [multiFilterContext, setMultiFilterContext] = useState([]);

  const setNominationsContext = useCallback(data => {
    setNominationContext(prevState => ({
      ...prevState,
      data,
    }));
  }, []);

  const setMultiFiltersContext = useCallback(data => {
    setMultiFilterContext(data);
  }, []);

  return { setNominationsContext, nominationsContext, setMultiFiltersContext, multiFilterContext };
};

export default useNominations;
