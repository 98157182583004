const kabayanInterviewConstant = {
  commodity_pond_type: {
    name: 'pond_type',
    label: 'Tipe Kolam',
  },
  commodity_pond_type_id: {
    name: 'pond_type_id',
    label: 'Tipe Kolam',
  },
  farmer_name: {
    name: 'name',
    label: 'Nama Petani',
    required: true,
  },
  point_name_interview: {
    name: 'point',
    label: 'Lokasi eFishery Point',
    required: true,
  },
  procuration_image: {
    name: 'procuration_image',
    label: 'Upload Surat Kuasa',
    accept: '.pdf,image/*',
  },
  business_certificate_image: {
    name: 'business_certificate_image',
    label: 'Upload Surat Keterangan Usaha',
    accept: '.pdf,image/*',
  },
  feeds_type: {
    name: 'feeds_type',
    label: 'Pakan yang digunakan',
  },
  feeds_type_id: {
    name: 'feeds_type_id',
    label: 'Pakan yang digunakan',
  },
  cultivation_procedure: {
    name: 'cultivation_procedure',
    label: 'Prosedur Budidaya',
  },
  cultivation_procedure_id: {
    name: 'cultivation_procedure_id',
    label: 'Prosedur Budidaya',
  },
  cultivation_note: {
    name: 'cultivation_note',
    label: 'Pencatatan Budidaya',
  },
  cultivation_note_id: {
    name: 'cultivation_note_id',
    label: 'Pencatatan Budidaya',
  },
  feed_supplier_type: {
    name: 'feed_supplier_type',
    label: 'Tipe Supplier Pakan',
  },
  feed_supplier_type_id: {
    name: 'feed_supplier_type_id',
    label: 'Tipe Supplier Pakan',
  },
  seed_supplier_type: {
    name: 'seed_supplier_type',
    label: 'Tipe Supplier Benih',
  },
  seed_supplier_type_id: {
    name: 'seed_supplier_type_id',
    label: 'Tipe Supplier Benih',
  },
  interview_buyer_receivables: {
    name: 'buyer_receivables',
    label: 'Piutang di pembeli',
  },
  interview_buyer_receivables_id: {
    name: 'buyer_receivables_id',
    label: 'Piutang di pembeli',
  },
  fish_buyer_payment_pattern: {
    name: 'fish_buyer_payment_pattern',
    label: 'Pola pembayaran pembeli',
  },
  fish_buyer_payment_pattern_id: {
    name: 'fish_buyer_payment_pattern_id',
    label: 'Pola pembayaran pembeli',
  },
  feed_supplier_reputation: {
    name: 'feed_supplier_reputation',
    label: 'Reputasi di Supplier/Agen Pakan',
  },
  feed_supplier_reputation_id: {
    name: 'feed_supplier_reputation_id',
    label: 'Reputasi di Supplier/Agen Pakan',
  },
  psoe_name: {
    name: 'psoe_name',
    label: 'Nama Petugas',
  },
  rent_pond_per_month: {
    name: 'rent_pond_per_month',
    label: 'Biaya Sewa Kolam per bulan (Rp)',
    format: 'idr',
  },
  number_of_ponds_last_year: {
    name: 'number_of_ponds_last_year',
    label: 'Jumlah Kolam Tahun Lalu (kolam)',
    format: 'int',
  },
  hpp_per_kg_avg: {
    name: 'hpp_per_kg_avg',
    label: 'Rata-rata HPP per kg',
    format: 'idr',
  },
  feed_supplier_contact: {
    name: 'feed_supplier_contact',
    label: 'No. Handphone Supplier Pakan',
    format: 'phone',
  },
  feed_supplier_address: {
    name: 'feed_supplier_address',
    label: 'Alamat Supplier Pakan',
  },
  seed_supplier_name: {
    name: 'seed_supplier_name',
    label: 'Nama Supplier Benih',
  },
  seed_supplier_contact: {
    name: 'seed_supplier_contact',
    label: 'No. Handphone Supplier Benih',
    format: 'phone',
  },
  piutang_di_pembeli_interview: {
    name: 'piutang_di_pembeli',
    label: 'Piutang di pembeli',
  },
  infrastructure_status_electricity: {
    name: 'infrastructure_status_electricity',
    label: 'Ketersediaan Listrik',
  },
  infrastructure_status_signal: {
    name: 'infrastructure_status_signal',
    label: 'Ketersediaan Sinyal',
  },
  infrastructure_network_service_name: {
    name: 'infrastructure_network_service_name',
    label: 'Nama Provider Internet',
  },
  infrastructure_network_service_name_other: {
    name: 'infrastructure_network_service_name_other',
    label: null,
  },
  infrastructure_status_delivery_car: {
    name: 'infrastructure_status_delivery_car',
    label: 'Mobil Pengiriman',
  },
  infrastructure_status_feed_warehouse: {
    name: 'infrastructure_status_feed_warehouse',
    label: 'Gudang Pakan',
  },
  infrastructure_status_cultivation_support_tools_id: {
    name: 'infrastructure_status_cultivation_support_tools_id',
    label: 'Alat Pendukung Budidaya (Aerator/ Kincir/ Smart Feeder/ Lainnya)',
  },
  infrastructure_status_cultivation_support_tools: {
    name: 'infrastructure_status_cultivation_support_tools',
    label: 'Alat Pendukung Budidaya (Aerator/ Kincir/ Smart Feeder/ Lainnya)',
  },
  credit_history: {
    name: 'credit_history',
    label: 'Credit History',
  },
  credit_history_id: {
    name: 'credit_history_id',
    label: 'Credit History',
  },
  financial_records: {
    name: 'financial_records',
    label: 'Financial Record',
  },
  financial_records_id: {
    name: 'financial_records_id',
    label: 'Financial Record',
  },
  disease_name: {
    name: 'disease_name',
    label: 'Nama Penyakit',
  },
  disease_cycle_per_year: {
    name: 'disease_cycle_per_year',
    label: 'Siklus Kena per Tahun',
  },
  disaster_name: {
    name: 'disaster_name',
    label: 'Nama Bencana',
  },
  disaster_frequency_per_year: {
    name: 'disaster_frequency_per_year',
    label: 'Frekuensi per Tahun',
  },
  /**
   * Rekomendasi Agen Pakan
   */
  feed_supplier_recommendation_name: {
    name: 'feed_supplier_recommendation_name',
    label: 'Nama Agen pakan',
  },
  feed_supplier_recommendation_location: {
    name: 'feed_supplier_recommendation_location',
    label: 'Lokasi',
  },
  feed_supplier_recommendation_date: {
    name: 'feed_supplier_recommendation_date',
    label: 'Tanggal',
  },
  feed_supplier_recommendation_score: {
    name: 'feed_supplier_recommendation_score',
    label: 'Rekomendasi',
  },
  feed_supplier_recommendation_score_id: {
    name: 'feed_supplier_recommendation_score_id',
    label: 'Rekomendasi',
  },
  feed_supplier_recommendation_note: {
    name: 'feed_supplier_recommendation_note',
    label: 'Alasan Rekomendasi',
  },
  pc_recommendation_name: {
    name: 'pc_recommendation_name',
    label: 'Nama PC/AM yang Menyetujui',
  },
  pc_recommendation_location: {
    name: 'pc_recommendation_location',
    label: 'Lokasi',
  },
  pc_recommendation_date: {
    name: 'pc_recommendation_date',
    label: 'Tanggal',
  },
  pc_recommendation_status: {
    name: 'pc_recommendation_status',
    label: 'Status (diisi oleh PC/AM)',
  },
  pc_recommendation_status_id: {
    name: 'pc_recommendation_status_id',
    label: 'Status (diisi oleh PC/AM)',
  },
  pc_recommendation_category: {
    name: 'pc_recommendation_category',
    label: 'Kategori',
  },
  pc_recommendation_result: {
    name: 'pc_recommendation_result',
    label: 'Rekomendasi',
    options: ['Ya', 'Tidak'],
  },
  pc_recommendation_score: {
    name: 'pc_recommendation_score',
    label: 'Alasan Rekomendasi',
  },
  pc_recommendation_score_id: {
    name: 'pc_recommendation_score_id',
    label: 'Alasan Rekomendasi',
  },
  pc_recommendation_note: {
    name: 'pc_recommendation_note',
    label: 'Catatan Rekomendasi',
  },
  surrounding_environment_recommendation_name: {
    name: 'surrounding_environment_recommendation_name',
    label: 'Nama Pemberi Rekomendasi',
  },
  surrounding_environment_recommendation_location: {
    name: 'surrounding_environment_recommendation_location',
    label: 'Lokasi',
  },
  surrounding_environment_recommendation_date: {
    name: 'surrounding_environment_recommendation_date',
    label: 'Tanggal',
  },
  surrounding_environment_recommendation_score: {
    name: 'surrounding_environment_recommendation_score',
    label: 'Rekomendasi',
  },
  surrounding_environment_recommendation_score_id: {
    name: 'surrounding_environment_recommendation_score_id',
    label: 'Rekomendasi',
  },
  surrounding_environment_recommendation_note: {
    name: 'surrounding_environment_recommendation_note',
    label: 'Alasan Rekomendasi',
  },
  application_limit: {
    name: 'application_limit',
    label: 'Limit Pengajuan',
    required: true,
    format: 'int',
  },
  feed_ordering_scheme: {
    name: 'feed_ordering_scheme',
    label: 'Skema Pemesanan Pakan',
    required: true,
  },
  feed_ordering_scheme_other: {
    name: 'feed_ordering_scheme_other',
    label: null,
    required: true,
  },
};

export default kabayanInterviewConstant;
