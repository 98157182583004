import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const HomePages = loadable(() => import(/* webpackChunkName: "HomePages" */ './index'));
const homeRoute = {
  routeProps: {
    exact: true,
    path: PATH.HOME,
    component: HomePages,
  },
  isPublic: false,
  name: 'Home',
};

export default homeRoute;
