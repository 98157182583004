const pengajuanUpgradingConstant = {
  // page 1 [v]
  lead_id: {
    name: 'lead_id',
    label: 'Lead Id',
    required: true,
  },
  farmer_name: {
    name: 'name',
    label: 'Nama Petani',
    required: true,
  },
  point_name_farmer: {
    name: 'point_name',
    label: 'Point',
    required: true,
  },
  phone_number: {
    name: 'phone_number',
    label: 'No HP',
    format: 'phone',
    required: true,
  },
  previous_kabayan_type: {
    name: 'previous_kabayan_type',
    label: 'Product Awal Kabayan',
    required: false,
  },
  previous_kabayan_limit: {
    name: 'previous_kabayan_limit',
    label: 'Limit Awal Kabayan',
    format: 'idr',
    required: false,
  },
  upgrading_kabayan_type: {
    name: 'upgrading_kabayan_type',
    label: 'Product Tujuan Kabayan Upgrading',
    required: true,
  },
  upgrading_kabayan_limit: {
    name: 'upgrading_kabayan_limit',
    label: 'Limit Pengajuan Upgrading',
    format: 'idr',
    required: true,
  },
  upgrading_reason: {
    name: 'upgrading_reason',
    label: 'Alasan Pengajuan Upgrading',
    required: true,
  },

  // page 2 [v]
  experience_cultivation_year_duration: {
    name: 'experience_cultivation_year_duration',
    label: 'Lama Pengalaman Budidaya (tahun)',
    format: 'int',
    required: true,
  },
  commodity_name: {
    name: 'commodity_name',
    label: 'Komoditas Budidaya',
  },
  commodity_id: {
    name: 'commodity_id',
    label: 'Nama Komoditas Utama',
    required: true,
  },
  is_has_cultivation_diversification: {
    name: 'is_has_cultivation_diversification',
    label: 'Apakah Petani melakukan diversifikasi budidaya ?',
    options: ['Ya', 'Tidak'],
    required: true,
  },
  other_commodity_id: {
    name: 'other_commodity_id',
    label: 'Komoditas budidaya lainnya (Maks 4)',
    required: true,
  },
  is_pond_address_moved: {
    name: 'is_pond_address_moved',
    label: 'Apakah Alamat Kolam Pindah ?',
    options: ['Ya', 'Tidak'],
    required: true,
  },
  pond_address: {
    name: 'pond_address',
    label: 'Alamat Kolam Saat Ini',
    required: true,
  },
  previous_total_pond_count: {
    name: 'previous_total_pond_count',
    label: 'Jumlah Kolam Keseluruhan Sebelumnya',
    format: 'thousand',
    required: true,
  },
  current_total_pond_count: {
    name: 'current_total_pond_count',
    label: 'Jumlah Kolam Keseluruhan Saat Ini',
    format: 'thousand',
    required: true,
  },
  current_active_pond_count: {
    name: 'current_active_pond_count',
    label: 'Jumlah Kolam Aktif Saat Ini',
    format: 'thousand',
    required: true,
  },
  pond_active_submission_count: {
    name: 'pond_active_submission_count',
    label: 'Jumlah Kolam Aktif Yang Diajukan',
    format: 'thousand',
    required: true,
  },
  smallest_pond_area_submission: {
    name: 'smallest_pond_area_submission',
    label: 'Luas Kolam Terkecil Yang Diajukan',
    format: 'thousand',
    required: true,
  },
  largest_pond_area_submisison: {
    name: 'largest_pond_area_submisison',
    label: 'Luas Kolam Terbesar Yang Diajukan',
    format: 'thousand',
    required: true,
  },
  seed_dispersal_per_pond: {
    name: 'seed_dispersal_per_pond',
    label: 'Jumlah Tebaran Per Kolam',
    format: 'thousand',
    required: true,
  },

  // page 3 [v]
  previous_feed_usage_sack: {
    name: 'previous_feed_usage_sack',
    label: 'Kebutuhan Pakan Per Kolam Sebelumnya (Sak)',
    format: 'thousand',
    required: true,
  },
  current_feed_usage_sack: {
    name: 'current_feed_usage_sack',
    label: 'Kebutuhan Pakan Per Kolam Saat Ini (Sak)',
    format: 'thousand',
    required: true,
  },
  feed_price_in_sack: {
    name: 'feed_price_in_sack',
    label: 'Harga Pakan Per Sak',
    format: 'idr',
    required: true,
  },
  feed_sack_unit_in_kg_options: {
    name: 'feed_sack_unit_in_kg_options',
    label: 'Satuan Sak Pakan per Kg',
    options: ['10', '20', '30', '40', '50', 'Lainnya'],
    required: true,
  },
  feed_sack_unit_in_kg: {
    name: 'feed_sack_unit_in_kg',
    label: 'Satuan Sak Pakan per Kg (Lainnya)',
    format: 'int',
    required: true,
  },
  previous_harvest_per_pond_in_kg: {
    name: 'previous_harvest_per_pond_in_kg',
    label: 'Hasil Panen Per Kolam Sebelumnya (Kg)',
    format: 'thousand',
    required: true,
  },
  current_harvest_per_pond_in_kg: {
    name: 'current_harvest_per_pond_in_kg',
    label: 'Hasil Panen Per Kolam Saat Ini (Kg)',
    format: 'thousand',
    required: true,
  },
  current_harvest_selling_price_per_kg: {
    name: 'current_harvest_selling_price_per_kg',
    label: 'Harga Jual Hasil Panen Per Kg Saat Ini (Rp)',
    format: 'idr',
    required: true,
  },
  production_cost_per_month: {
    name: 'production_cost_per_month',
    label: 'Biaya Produksi Per Bulan (Rp)',
    format: 'idr',
    required: true,
  },
  operational_cost_per_month: {
    name: 'operational_cost_per_month',
    label: 'Biaya Operasional Per Bulan (Rp)',
    format: 'idr',
    required: true,
  },
  is_has_other_profession: {
    name: 'is_has_other_profession',
    label: 'Apakah Petani memiliki Profesi Lainnya?',
    options: ['Ya', 'Tidak'],
    required: true,
  },
  other_profession: {
    name: 'other_profession',
    label: 'Profesi Lainnya',
    required: true,
  },
  other_profession_income: {
    name: 'other_profession_income',
    label: 'Pendapatan atas Profesi Lainnya (Rp)',
    format: 'idr',
    required: true,
  },

  // page 4 [-]
  // Surat Keterangan Usaha (SKU)
  // Form Registarsi Member & Form Interview (TBD????)
  ktp_image_url: {
    name: 'ktp_image_url',
    label: 'KTP Petani',
    required: true,
  },
  farmer_selfie_image_url: {
    name: 'farmer_selfie_image_url',
    label: 'Foto KYC Petani',
    required: true,
  },
  spouse_ktp_url: {
    name: 'spouse_ktp_url',
    label: 'KTP Pasangan Petani',
    required: false,
  },
  domicile_statement_url: {
    name: 'domicile_statement_url',
    label: 'Surat Keterangan Domisili (SKD)',
    required: false,
  },
  family_card_url: {
    name: 'family_card_url',
    label: 'Kartu Keluarga',
    required: true,
  },
  business_certificate_image_url: {
    name: 'business_certificate_image_url',
    label: 'Surat Keterangan Usaha (SKU)',
    required: false,
  },
  house_image_in_wide_url: {
    name: 'house_image_in_wide_url',
    label: 'Foto Rumah Petani Tampak Jauh',
    required: false,
  },
  house_image_with_farmer_url: {
    name: 'house_image_with_farmer_url',
    label: 'Foto Rumah Petani Bersama Petani & FBDC',
    required: false,
  },
  npwp_image_url: {
    name: 'npwp_image_url',
    label: 'NPWP',
    required: false,
  },
  pond_ownership_certificate_url: {
    name: 'pond_ownership_certificate_url',
    label: 'Surat Kepemilikan Kolam',
    required: true,
  },
  house_ownership_certificate_url: {
    name: 'house_ownership_certificate_url',
    label: 'Surat Kepemilikan Rumah',
    required: false,
  },
  fish_sales_receipt_last_3_months_url: {
    name: 'fish_sales_receipt_last_3_months_url',
    label: 'Nota Penjualan Ikan 3 bulan terakhir',
    required: false,
  },
  feed_purchase_receipt_last_3_months_url: {
    name: 'feed_purchase_receipt_last_3_months_url',
    label: 'Nota Pembelian Pakan 3 bulan terakhir',
    required: false,
  },
  account_mutation_url: {
    name: 'account_mutation_url',
    label: 'Mutasi Rekening Koran',
    required: false,
  },
  assets_photo_url: {
    name: 'assets_photo_url',
    label: 'Foto Aset Keseluruhan',
    required: false,
  },
  upgrading_form_url: {
    name: 'upgrading_form_url',
    label: 'Form Upgrading',
    required: true,
  },

  // page 5
  pond_photos_url: {
    name: 'pond_photos_url',
    label: 'Foto Kolam Keseluruhan Petani',
    required: true,
  },
  farmer_feeding_in_pond_photo_url: {
    name: 'farmer_feeding_in_pond_photo_url',
    label: 'Foto Petani Memberi Pakan Ikan',
    required: false,
  },
  farmer_photo_with_officer_url: {
    name: 'farmer_photo_with_officer_url',
    label: 'Foto FBDC/FBDO bersama Petani',
    required: false,
  },
  pond_main_commodity_photo_url: {
    name: 'pond_main_commodity_photo_url',
    label: 'Foto Kolam Aktif Komoditas Utama',
    required: false,
  },

  // page 6
  other_document_url: {
    name: 'other_document_url',
    label: 'Dokumen lainnya',
    required: false,
  },
  other_information: {
    name: 'other_information',
    label: 'Informasi Lainnya',
    required: false,
  },

  // wording upgrading
  wording_upgrading: {
    1: 'Informasi Awal',
    2: 'Informasi Budidaya Petani',
    3: 'Informasi Kebutuhan Pakan & Biaya-biaya',
    4: 'Informasi Dokumen',
    5: 'Foto Kolam Terbaru',
    6: 'Informasi Lainnya',
  },

  list_kabayan_type: [
    {
      id: 1,
      name: 'Kabayan Reguler - Fish',
      code: 'KBR',
      group: 'SME',
      business_commodity: 'FISH',
      complete_name: '',
    },
    {
      id: 2,
      name: 'Kabayan Express - Fish',
      code: 'KBX',
      group: 'RETAIL',
      business_commodity: 'FISH',
      complete_name: '',
    },
    {
      id: 3,
      name: 'Kabayan Lite - Fish',
      code: 'KBL',
      group: 'RETAIL',
      business_commodity: 'FISH',
      complete_name: '',
    },
    {
      id: 4,
      name: 'Kabayan Jawara - Fish',
      code: 'KBJ',
      group: 'SME',
      business_commodity: 'FISH',
      complete_name: '',
    },
    {
      id: 5,
      name: 'Kabayan Inti Plasma - Fish',
      code: 'KBIP',
      group: 'COMMERCIAL',
      business_commodity: 'FISH',
      complete_name: '',
    },
    {
      id: 6,
      name: 'Kabayan Bisnis - Fish',
      code: 'KBBI',
      group: 'COMMERCIAL',
      business_commodity: 'FISH',
      complete_name: '',
    },
    {
      id: 9,
      name: 'Kabayan Reguler - Shrimp',
      code: 'KBR-SH',
      group: 'SME',
      business_commodity: 'SHRIMP',
      complete_name: '',
    },
    {
      id: 10,
      name: 'Kabayan Express - Shrimp',
      code: 'KBX-SH',
      group: 'RETAIL',
      business_commodity: 'SHRIMP',
      complete_name: '',
    },
    {
      id: 11,
      name: 'Kabayan Lite - Shrimp',
      code: 'KBL-SH',
      group: 'RETAIL',
      business_commodity: 'SHRIMP',
      complete_name: '',
    },
    {
      id: 12,
      name: 'Kabayan Jawara - Shrimp',
      code: 'KBJ-SH',
      group: 'SME',
      business_commodity: 'SHRIMP',
      complete_name: '',
    },
    {
      id: 13,
      name: 'Kabayan Jawara++ - Shrimp',
      code: 'KBJP',
      group: 'SME',
      business_commodity: 'SHRIMP',
      complete_name: '',
    },
    {
      id: 17,
      name: 'Mitra Produksi Ikan',
      code: 'MPI',
      group: 'Mitra Produksi',
      business_commodity: 'FISH',
      complete_name: '',
    },
    {
      id: 16,
      name: 'Tenor Shrimp Historical',
      code: 'TKBY',
      group: '',
      business_commodity: 'SHRIMP',
      complete_name: '',
    },
    {
      id: 8,
      name: 'TRANSISI KABAYAN',
      code: 'TKBY',
      group: 'TRANSISI',
      business_commodity: '',
      complete_name: '',
    },
  ],
};

export default pengajuanUpgradingConstant;
