const wording = {
  form_pengajuan_ulang: {
    1: { title: 'Reject Call', desc: 'Tolak Internal Karena petani tidak dapat dihubungi selama lebih dari 2 hari' },
    2: {
      title: 'Tolak Internal Karena Dokumen',
      desc: 'Tolak internal karena dokumen belum lengkap. Segera lengkapi melalui form ini',
      subdesc: 'Checklist salah satu dari form Checklist Dokumen yang di Upload atau Checklist Data yang di Update',
    },
    3: {
      title: 'Tolak Internal Karena Data',
      desc: 'Informasi terkait perubahan data di eFisheryku',
      subdesc: 'Checklist salah satu opsi dari form',
    },
    4: {
      title: 'Masa Berlaku PKS Expired',
      desc: 'Jika Masa Berlaku PKS Expired, maka wajib upload seluruh dokumen',
      subdesc: 'Checklist salah satu opsi dari form ',
    },
  },
};

export default wording;
