import { useState, useCallback } from 'react';

const useMembers = () => {
  const [membersContext, setMemberContext] = useState();
  const [membersDraftContext, setMemberDraftContext] = useState([]);

  const setMembersContext = useCallback(data => {
    setMemberContext(prevState => ({
      ...prevState,
      data,
    }));
  }, []);

  const setMembersDraftContext = useCallback(data => {
    setMemberDraftContext(prevState => ({
      ...prevState,
      data,
    }));
  }, []);

  return { setMembersContext, membersContext, setMembersDraftContext, membersDraftContext };
};

export default useMembers;
