const cookiesName = () => {
  const tokenName = {
    staging: 'stg_ef_token',
    uat: 'uat_ef_token',
    production: 'ef_token',
  };
  return tokenName[process.env.REACT_APP_ENV] ?? '';
};

export const cookiesNubitoolsName = () => {
  const tokenName = {
    staging: 'stg_nubitools_access_token',
    uat: 'uat_nubitools_access_token',
    production: 'nubitools_access_token',
  };
  return tokenName[process.env.REACT_APP_ENV] ?? '';
};

export default cookiesName;
