import { useCookies } from 'react-cookie';
import cookiesName, { cookiesNubitoolsName } from 'utils/helpers/cookiesName';

const useGetToken = () => {
  const [cookies] = useCookies([cookiesName()]);

  // INFO will be deprecated
  const token = cookies[cookiesName()];
  const tokenNubitools = cookies[cookiesNubitoolsName()];

  return { token, tokenNubitools };
};

export default useGetToken;
