import { useState, useCallback } from 'react';

const useMembers1 = () => {
  const [members1Context, setMember1Context] = useState();

  const setMembers1Context = useCallback(data => {
    setMember1Context(prevState => ({
      ...prevState,
      data,
    }));
  }, []);

  return { setMembers1Context, members1Context };
};

export default useMembers1;
