import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const HistoryNominatifRenewalFunctionPages = loadable(() => import(/* webpackChunkName: "InterviewPages" */ './index'));
const historyNominatifRenewalFunctionRoute = {
  routeProps: {
    path: PATH.HISTORY_NOMINATIF_RENEWAL,
    component: HistoryNominatifRenewalFunctionPages,
  },
  isPublic: false,
  name: 'HistoryNominatifRenewalFunction',
};

export default historyNominatifRenewalFunctionRoute;
