import React from "react";
import EventEmitter from "events";

const KEY_LANG = "lang";
const EVENT_LANG_CHANGED = "EVENT_LANG_CHANGED";

class Translator {
  constructor() {
    this.emitter = new EventEmitter();
    this.lang = "";
    this.translations = {};
  }

  initialize = async (translations) => {
    this.translations = translations;
    const lang = window && window.localStorage.getItem(KEY_LANG);
    if (lang) {
      this.setLang(lang);
    }
  };

  setLang = (lang) => {
    if (lang === this.lang) return;
    this.lang = lang;
    window && window.localStorage.setItem(KEY_LANG, lang);
    this.emitter.emit(EVENT_LANG_CHANGED, lang);
  };

  translate = (text, ...args) => {
    if (text in this.translations) {
      const spec = this.translations[text];
      if (this.lang in spec) {
        const result = spec[this.lang];
        if (typeof result === "function") {
          return result(...args);
        } else {
          return result;
        }
      }
    }
    return text;
  };

  attachObjectTranslator = (obj) => {
    if (typeof obj !== "object") return;
    if (!obj.translations) return;
    const self = this;
    // eslint-disable-next-line no-unused-vars
    for (let [field, translation] of Object.entries(obj.translations)) {
      const defaultValue = obj[field];
      Object.defineProperty(obj, field, {
        get: function () {
          return self.lang in translation
            ? translation[self.lang]
            : defaultValue;
        },
      });
    }
  };

  subscribe = (subscriber) => {
    this.emitter.addListener(EVENT_LANG_CHANGED, subscriber);
    return () => this.unsubscribe(subscriber);
  };

  unsubscribe = (subscriber) => {
    this.emitter.removeListener(EVENT_LANG_CHANGED, subscriber);
  };
}

const translator = new Translator();
export default translator;
if (process.env.NODE_ENV !== "production") {
  // window.translator = translator;
}

export const tt = translator.translate;

export const useTranslator = () => {
  const [lang, set_lang] = React.useState(translator.lang);
  React.useEffect(() => {
    const listener = () => set_lang(translator.lang);
    translator.emitter.addListener(EVENT_LANG_CHANGED, listener);
    return () =>
      translator.emitter.removeListener(EVENT_LANG_CHANGED, listener);
  }, []);
  return [tt, lang];
};
