import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const RenewalTrackerPage = loadable(() => import(/* webpackChunkName: "RenewalTrackerPage" */ './index'));

const renewalTrackerRoute = {
  routeProps: {
    path: PATH.RENEWAL_TRACKER,
    component: RenewalTrackerPage,
  },
  isPublic: false,
  name: 'renewal_tracker',
};

export default renewalTrackerRoute;
