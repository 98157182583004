import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const NominationCoopSubmissionPage = loadable(() =>
  import(/* webpackChunkName: "NominationCoopSubmissionPage" */ './index'),
);
export const nominationCoopSubmissionRoute = {
  routeProps: {
    exact: true,
    path: PATH.NOMINATION_COOP_SUBMISSION,
    component: NominationCoopSubmissionPage,
  },
  isPublic: false,
  name: 'nomination_coop_submission',
};

export default [nominationCoopSubmissionRoute];
