import { styled } from 'twin.macro';
import pixelToRem from 'utils/helpers/pixelToRem';

export const Container = styled.div`
  ${(process.env.REACT_APP_MODE === 'staging' || process.env.REACT_APP_MODE === 'UAT') && `margin-top: 25px`}
`;

export const BannerContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
`;

export const BannerApp = styled.div`
  font-size: ${pixelToRem(14)};
  background: #dbf4ec;
  width: 100%;
  text-align: center;
  font-weight: 500;
  height: 25px;
`;

export const LinkAction = styled.a`
  color: white;
  display: block;
`;
