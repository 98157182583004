import React, { useContext } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthProvider } from 'utils/context/AuthContext';
import { FlagrContext } from 'utils/context/FlagrContext';
import { AnimatePresence } from 'framer-motion';
import NotFoundPage from 'containers/NotFound';
import UnderConstruction from 'containers/UnderConstruction';
import routes from './routes';
import PrivateRoute from './components/PrivateRoute';

const Routing = () => {
  const { APP_IS_UNDER_CONSTRUCTION } = useContext(FlagrContext);

  return (
    <AuthProvider>
      <Router>
        <AnimatePresence exitBeforeEnter>
          <Switch>
            {routes.map(obj => {
              const { routeProps } = obj;
              if (obj.isPublic) {
                return (
                  <Route
                    key={routeProps.path}
                    component={APP_IS_UNDER_CONSTRUCTION?.enabled && UnderConstruction}
                    {...(!APP_IS_UNDER_CONSTRUCTION?.enabled && { ...routeProps })}
                  />
                );
              }
              return (
                <PrivateRoute
                  isUnderConstruction={APP_IS_UNDER_CONSTRUCTION?.enabled}
                  isGuest={obj.isGuest}
                  key={routeProps.path}
                  {...routeProps}
                />
              );
            })}
            <Route component={NotFoundPage} />
          </Switch>
        </AnimatePresence>
      </Router>
    </AuthProvider>
  );
};
export default Routing;
