import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const PengajuanUlangFunctionPages = loadable(() => import(/* webpackChunkName: "InterviewPages" */ './index'));
const pengajuanUlangFunctionRoute = {
  routeProps: {
    path: PATH.PENGAJUAN_ULANG,
    component: PengajuanUlangFunctionPages,
  },
  isPublic: false,
  name: 'PengajuanUlangFunction',
};

export default pengajuanUlangFunctionRoute;
