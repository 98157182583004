import loadable from '@loadable/component';
import { PATH } from '../../routes/constants';

const ReactivationPage = loadable(() => import(/* webpackChunkName: "ReactivationPage" */ './index'));
const reactivationRoute = {
  routeProps: {
    path: PATH.PENGAJUAN_REACTIVATION,
    component: ReactivationPage,
  },
  isPublic: false,
  name: 'PengajuanUpgradingFunction',
};

export default reactivationRoute;
